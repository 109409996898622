
export const GET_WHATSAPPDATA_ERROR = 'GET_WHATSAPPDATA_ERROR'
export const GET_WHATSAPPDATA_SUCCESS = 'GET_WHATSAPPDATA_SUCCESS'
export const GET_WHATSAPPDATA_REQUEST = 'GET_WHATSAPPDATA_REQUEST'
export const GET_WHATSAPPPROFILE_REQUEST = 'GET_WHATSAPPPROFILE_REQUEST'
export const GET_WHATSAPPPROFILE_ERROR = 'GET_WHATSAPPPROFILE_ERROR'
export const GET_WHATSAPPPROFILE_SUCCESS = 'GET_WHATSAPPPROFILE_SUCCESS'

export const ADD_WHATSAPPDATA_TOLOCALSTORE = 'ADD_WHATSAPPDATA_TOLOCALSTORE'
export const ADD_WHATSAPPPROFILE_TOLOCALSTORE = 'ADD_WHATSAPPPROFILE_TOLOCALSTORE'

export const DEL_WHATSAPPDATA_FROMLOCALSTORE = 'DEL_WHATSAPPDATA_FROMLOCALSTORE'


export const SET_WHATSAPPDATA_ERROR = 'SET_WHATSAPPDATA_ERROR'
export const SET_WHATSAPPDATA_SUCCESS = 'SET_WHATSAPPDATA_SUCCESS'
export const SET_WHATSAPPDATA_REQUEST = 'SET_WHATSAPPDATA_REQUEST'

export const SET_WHATSAPPPROFILE_ERROR = 'SET_WHATSAPPPROFILE_ERROR'
export const SET_WHATSAPPPROFILE_SUCCESS = 'SET_WHATSAPPPROFILE_SUCCESS'
export const SET_WHATSAPPPROFILE_REQUEST = 'SET_WHATSAPPPROFILE_REQUEST'

export const DEL_WHATSAPPPROFILE_ERROR = 'DEL_WHATSAPPPROFILE_ERROR'
export const DEL_WHATSAPPPROFILE_SUCCESS = 'DEL_WHATSAPPPROFILE_SUCCESS'
export const DEL_WHATSAPPPROFILE_REQUEST = 'DEL_WHATSAPPPROFILE_REQUEST'

export const UPDATE_WHATSAPPPROFILE_ERROR = 'UPDATE_WHATSAPPPROFILE_ERROR'
export const UPDATE_WHATSAPPPROFILE_SUCCESS = 'UPDATE_WHATSAPPPROFILE_SUCCESS'
export const UPDATE_WHATSAPPPROFILE_REQUEST = 'UPDATE_WHATSAPPPROFILE_REQUEST'



export const getWhatsappDataRequest = (payload) => ({
    type: GET_WHATSAPPDATA_REQUEST, 
    payload
})
export const getWhatsappProfileRequest = (payload) => ({
    type: GET_WHATSAPPPROFILE_REQUEST, 
    payload
})

export const getWhatsappDataSuccess = (payload) => ({
    type: GET_WHATSAPPDATA_SUCCESS,
    payload
})

export const getWhatsappDataError = (error) => ({
    type: GET_WHATSAPPDATA_ERROR,
    error
})


export const setWhatsappDataRequest = (payload) => ({
    type: SET_WHATSAPPDATA_REQUEST, 
    payload
})


export const setWhatsappDataSuccess = (payload) => ({
    type: SET_WHATSAPPDATA_SUCCESS,
    payload
})

export const setWhatsappDataError = (error) => ({
    type: SET_WHATSAPPDATA_ERROR,
    error
})

export const setWhatsappProfileRequest = (payload) => ({
    type: SET_WHATSAPPPROFILE_REQUEST, 
    payload
})


export const setWhatsappProfileSuccess = (payload) => ({
    type: SET_WHATSAPPPROFILE_SUCCESS,
    payload
})

export const setWhatsappProfileError = (error) => ({
    type: SET_WHATSAPPPROFILE_ERROR,
    error
})

export const delWhatsappDataFromLocalStore = (payload) => ({
    type: DEL_WHATSAPPDATA_FROMLOCALSTORE,
    payload
})

export const addWhatsappDataToLocalStore = (payload) => ({
    type: ADD_WHATSAPPDATA_TOLOCALSTORE,
    payload
})

export const addWhatsappProfileToLocalStore = (payload) => ({
    type: ADD_WHATSAPPPROFILE_TOLOCALSTORE,
    payload
})

export const removeWhatsappProfile = (payload) => ({
    type: DEL_WHATSAPPPROFILE_REQUEST,
    payload
})

export const updateWhatsappProfile = (payload) => ({
    type: UPDATE_WHATSAPPPROFILE_REQUEST,
    payload
})