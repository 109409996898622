/**
 * Clip action types
 */
export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';

export const GROUPS_ERROR = 'GROUPS_ERROR';

export const getGroupsRequest = (payload) => ({
    type: GET_GROUPS_REQUEST,
    payload
});

export const getGroupsSuccess = (payload) => ({
    type: GET_GROUPS_SUCCESS,
    payload
});

export const groupsError = (error) => ({
    type: GROUPS_ERROR,
    error
});