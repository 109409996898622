/**
 * Clip category types
 */
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';

export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';

export const getCategoriesRequest = (payload) => ({
    type: GET_CATEGORIES_REQUEST,
    payload
});

export const getCategoriesSuccess = (payload) => ({
    type: GET_CATEGORIES_SUCCESS,
    payload
});

export const categoriesError = (error) => ({
    type: CATEGORIES_ERROR,
    error
});
