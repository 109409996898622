
export const GET_TEMPLATESECTIONSFORSUPERGROUP_ERROR = 'GET__TEMPLATESECTIONSFORSUPERGROUP_ERROR'
export const GET_TEMPLATESECTIONSFORSUPERGROUP_SUCCESS = 'GET__TEMPLATESECTIONSFORSUPERGROUP_SUCCESS'
export const GET_TEMPLATESECTIONSFORSUPERGROUP_REQUEST = 'GET__TEMPLATESECTIONSFORSUPERGROUP_REQUEST'
export const ADD_TEMPLATESECTIONSFORSUPERGROUP_TOLOCALSTORE = 'ADD__TEMPLATESECTIONSFORSUPERGROUP_TOLOCALSTORE'
export const DEL_TEMPLATESECTIONSFORSUPERGROUP_FROMLOCALSTORE = 'DEL__TEMPLATESECTIONSFORSUPERGROUP_FROMLOCALSTORE'


export const SET_TEMPLATESECTIONSFORSUPERGROUP_ERROR = 'SET_TEMPLATESECTIONSFORSUPERGROUP_ERROR'
export const SET_TEMPLATESECTIONSFORSUPERGROUP_SUCCESS = 'SET_TEMPLATESECTIONSFORSUPERGROUP_SUCCESS'
export const SET_TEMPLATESECTIONSFORSUPERGROUP_REQUEST = 'SET_TEMPLATESECTIONSFORSUPERGROUP_REQUEST'



export const getSectionsFORSUPERGROUPRequest = (payload) => ({
    type: GET_TEMPLATESECTIONSFORSUPERGROUP_REQUEST, 
    payload
})


export const getSectionsFORSUPERGROUPSuccess = (payload) => ({
    type: GET_TEMPLATESECTIONSFORSUPERGROUP_SUCCESS,
    payload
})

export const getSectionsFORSUPERGROUPError = (error) => ({
    type: GET_TEMPLATESECTIONSFORSUPERGROUP_ERROR,
    error
})


export const setSectionsFORSUPERGROUPRequest = (payload) => ({
    type: SET_TEMPLATESECTIONSFORSUPERGROUP_REQUEST, 
    payload
})


export const setSectionsFORSUPERGROUPSuccess = (payload) => ({
    type: SET_TEMPLATESECTIONSFORSUPERGROUP_SUCCESS,
    payload
})

export const setSectionsFORSUPERGROUPError = (error) => ({
    type: SET_TEMPLATESECTIONSFORSUPERGROUP_ERROR,
    error
})


export const delSectionsFORSUPERGROUPFromLocalStore = (payload) => ({
    type: DEL_TEMPLATESECTIONSFORSUPERGROUP_FROMLOCALSTORE,
    payload
})

export const addSectionsFORSUPERGROUPToLocalStore = (payload) => ({
    type: ADD_TEMPLATESECTIONSFORSUPERGROUP_TOLOCALSTORE,
    payload
})