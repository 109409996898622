import * as actionTypes from './action';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    error: undefined,
    microcategories: [], //a choosen traininghierarchy
    microareas: [], //all traininghierarchies
    currentmicroarea: null,
    categorytree: [], // a choosen converted traininghierarchy
    selectOptions: [],
    traininggroups: [],
    trainingsessions: [],
    savestatus: undefined,
    certificates: [],
    microoptions: [],
    usercertificates: [],
    audits: []
};

/* BEGIN OF functions for building the categorytree */

function  buildCatTable(parent, level, allCategoryObjects, catTree) {//recursive function for building categories
  let cats = new Array();
  cats = getConnectedCategories(allCategoryObjects, parent);

  if (cats)
  cats.map(cat => {
    catTree.push({ level: level, category: cat })
    buildCatTable(cat.id, level + 1, allCategoryObjects, catTree);
  })
};

function getConnectedCategories(allCategoryObjects, parentid) { // return all categories which belong to the given parent
  let cats = new Array();
  allCategoryObjects.map(currlevel => {
    if (currlevel.parentId == parentid) {
      cats.push(currlevel);
    }
  });
  return cats;
}

/* END OF BUILDING FUNCTIONS FOR CATEGORYTREE */

// used for component SelectSearch
function generateOptions(microcats) {

  var categoryObject = new Array();

  microcats ? categoryObject =  Object.values(microcats.map(mc => {
  let localob = Object.assign(mc.category, {  name: mc.category.value, value: mc.category.id,  photo: mc.category.thumbnail })

  return localob
}
)) : categoryObject = []

return categoryObject
}


const microCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_MICROCATEGORIES_SUCCESS:


          state.categorytree = new Array()

          buildCatTable(null, 0, action.payload, state.categorytree)

          console.log('microraw is: ', action.payload)
          console.log('cat-tree is: ', state.categorytree)

          var optionsObject = new Array()
          optionsObject =  generateOptions(state.categorytree)
          console.log('optionsobject is: ', optionsObject)


            return Object.assign({}, state, {
                isLoading: false,
                microcategories: [...action.payload],
                selectOptions: optionsObject,
                categorytree: [...state.categorytree]
            });



        case actionTypes.GET_MICROOPTIONS_SUCCESS:

            return Object.assign({}, state, {
                isLoading: false,
                microoptions: [...action.payload.trainingoptions]
            })

        case actionTypes.SET_MICROOPTIONS_LOCAL:

        console.log('Action Payload in set_microoptions_local is: ', action.payload)
        return Object.assign({}, state, {
            isLoading: false,
            microoptions: [...action.payload]
        })


        case actionTypes.GET_AUDIT_SUCCESS:

        return Object.assign({}, state, {
          isLoading: false,
          audits: [...action.payload.audits],

      })





        case actionTypes.SET_MICROAREA_SUCCESS:

        let retobj = Object.assign({}, state, {
          isLoading: false,
          microareas: [...action.payload.traininghierarchies],

      })




        if (action.payload.method === "PUT" && action.payload.hasOwnProperty("currentid") && action.payload.currentid){
          let localmicroarea = action.payload.traininghierarchies.find((th) => th.id === action.payload.currentid)
          let localmicrocats = localmicroarea.hierarchy
          state.categorytree = []
          buildCatTable(null, 0, localmicrocats, state.categorytree)
          var optionsObject = []
          optionsObject =  generateOptions(state.categorytree)
          retobj.microcategories = [...localmicrocats]
          retobj.selectOptions = optionsObject
          retobj.categorytree = [...state.categorytree]
          retobj.currentmicroarea = localmicroarea

        }

        console.log('in set microarea_success reducer: ', retobj)

        return retobj;



      case actionTypes.SET_LOCAL_MICROAREA:

        return Object.assign({}, state, {
          isLoading: false,
          currentmicroarea: action.payload,

      });

       case actionTypes.CLEAR_LOCAL_MICROCATEGORIES:
       return Object.assign({}, state, {
        isLoading: false,
        categorytree: [],
        microcategories: [],
        selectOptions: []
    });


        case actionTypes.GET_MICROAREA_SUCCESS:


            return Object.assign({}, state, {
                isLoading: false,
                microareas: [...action.payload],
            });



            case actionTypes.DEL_MICROCATEGORY_FROMLOCALSTORE:


        if (action.payload.id)
         state.microcategories = state.microcategories.filter( f => f.id != action.payload.id);
        if (action.payload.ids)
         state.microcategories = state.microcategories.filter( f => !action.payload.ids.includes(f));

         let ct = []
         buildCatTable(null, 0, state.microcategories, ct)

         var optionsObject = []
         optionsObject =  generateOptions(ct)


        return Object.assign({}, state, {
          isLoading: false,
          microcategories:  [...state.microcategories],
          selectOptions: optionsObject,
          categorytree: [...ct]

      });

      case actionTypes.ADD_MICROCATEGORY_TOLOCALSTORE:

      state.categorytree = new Array()
      state.microcategories.push(action.payload.category)
      buildCatTable(null, 0, state.microcategories, state.categorytree)

      var optionsObject = []
      optionsObject =  generateOptions(state.categorytree)

      return Object.assign({}, state, {
        isLoading: false,
        microcategories:  state.microcategories,
        selectOptions: optionsObject,
    });

        case actionTypes.GET_MICROCATEGORIES_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });


            case actionTypes.SET_MICROCATEGORIES_ERROR:

            Swal.fire({
              title: 'Error!',
              position: 'center',
              text: 'Problem beim speichern der Daten!',
              type: 'error',
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })
            return Object.assign({}, state, {
                isLoading: false,
                savestatus: false
            });

            case actionTypes.SET_MICROCATEGORIES_SUCCESS:

            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Daten erfolgreich gespeichert \n',
              showConfirmButton: false,
              timer: 1500
            })
            return Object.assign({}, state, {
                isLoading: false,
                savestatus: true
            });

            case actionTypes.SET_MICROAREA_SUCCESS:

            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Daten erfolgreich gespeichert \n',
              showConfirmButton: false,
              timer: 1500
            })
            console.log('data is now ', [...state.microareas, action.payload] )

            let wprofs = state.microareas

            if (action.payload.method === "POST")
              wprofs = [...state.microareas, action.payload.areas]
            else if (action.payload.method === "PUT"){
              wprofs = state.microareas.map(e => {
                if (e.id === action.payload.profile.id)
                return action.payload.profile

                return e
              }
                )

            }

            return Object.assign({}, state, {
                microareas: [...wprofs],
                isLoading: false,
                savestatus: true
            });


            case actionTypes.SET_LOCAL_CATEGORYTREE:

            let localcattree = state.categorytree
            let item = localcattree.find((ct) => ct.id === action.payload.category.id)
            item = action.payload.category

            console.log('SET_LOCAL_CATEGORYTREE value is: ', localcattree)

            return Object.assign({}, state, {
                isLoading: false,
                categorytree: [...localcattree]
            });



/*------------------------START TRAININGSESSION---------------------------*/
case actionTypes.GET_TRAININGSESSION_SUCCESS:

  return Object.assign({}, state, {
      isLoading: false,
      trainingsessions: [...action.payload.trainingsessions]
  });



case actionTypes.GET_TRAININGSESSION_ERROR:

  return Object.assign({}, state, {
      isLoading: false,
      error: action.payload.error
  });

  case actionTypes.PUT_TRAININGSESSION_SUCCESS:

      return Object.assign({}, state, {
          isLoading: false,
          trainingsessions: [...action.payload.trainingsessions]
      });

  case actionTypes.PUT_TRAININGSESSION_ERROR:

      return Object.assign({}, state, {
          isLoading: false,
          error: action.payload.error
      });

      case actionTypes.DEL_TRAININGSESSION_SUCCESS:

        return Object.assign({}, state, {
            isLoading: false,
            trainingsessions: [...action.payload.trainingsessions]
        });

    case actionTypes.DEL_TRAININGSESSION_ERROR:

        return Object.assign({}, state, {
            isLoading: false,
            error: action.payload.error
        });


      case actionTypes.POST_TRAININGSESSION_SUCCESS:

          return Object.assign({}, state, {
              isLoading: false,
              trainingsessions: [...action.payload.trainingsessions]
          });

      case actionTypes.POST_TRAININGSESSION_ERROR:

          return Object.assign({}, state, {
              isLoading: false,
              error: action.payload.error
          });

    /*------------------------END TRAININGSESSION---------------------------*/


/*------------------------START TRAININGGROUP---------------------------*/
case actionTypes.GET_TRAININGGROUP_SUCCESS:

  return Object.assign({}, state, {
      isLoading: false,
      traininggroups: [...action.payload.traininggroups]
  });



case actionTypes.GET_TRAININGGROUP_ERROR:

  return Object.assign({}, state, {
      isLoading: false,
      error: action.payload.error
  });

  case actionTypes.PUT_TRAININGGROUP_SUCCESS:

      return Object.assign({}, state, {
          isLoading: false,
          traininggroups: [...action.payload.traininggroups]
      });

  case actionTypes.PUT_TRAININGGROUP_ERROR:

      return Object.assign({}, state, {
          isLoading: false,
          error: action.payload.error
      });

      case actionTypes.DEL_TRAININGGROUP_SUCCESS:

        return Object.assign({}, state, {
            isLoading: false,
            traininggroups: [...action.payload.traininggroups]
        });

    case actionTypes.DEL_TRAININGGROUP_ERROR:

        return Object.assign({}, state, {
            isLoading: false,
            error: action.payload.error
        });


      case actionTypes.POST_TRAININGGROUP_SUCCESS:

          return Object.assign({}, state, {
              isLoading: false,
              traininggroups: [...action.payload.traininggroups]
          });

      case actionTypes.POST_TRAININGGROUP_ERROR:

          return Object.assign({}, state, {
              isLoading: false,
              error: action.payload.error
          });

    /*------------------------END TRAININGGROUP---------------------------*/





/*------------------------START CERTIFICATES TEXTS---------------------------*/
case actionTypes.GET_CERTIFICATE_SUCCESS:

  return Object.assign({}, state, {
      isLoading: false,
      certificates: [...action.payload.certificates]
  });



case actionTypes.GET_CERTIFICATE_ERROR:

  return Object.assign({}, state, {
      isLoading: false,
      error: action.payload.error
  });

  case actionTypes.PUT_CERTIFICATE_SUCCESS:

      return Object.assign({}, state, {
          isLoading: false,
          certificates: [...action.payload.certificates]
      });

  case actionTypes.PUT_CERTIFICATE_ERROR:

      return Object.assign({}, state, {
          isLoading: false,
          error: action.payload.error
      });

      case actionTypes.DEL_CERTIFICATE_SUCCESS:

        return Object.assign({}, state, {
            isLoading: false,
            certificates: [...action.payload.certificates]
        });

    case actionTypes.DEL_CERTIFICATE_ERROR:

        return Object.assign({}, state, {
            isLoading: false,
            error: action.payload.error
        });


      case actionTypes.POST_CERTIFICATE_SUCCESS:

          return Object.assign({}, state, {
              isLoading: false,
              certificates: [...action.payload.certificates]
          });

      case actionTypes.POST_CERTIFICATE_ERROR:

          return Object.assign({}, state, {
              isLoading: false,
              error: action.payload.error
          });

    /*------------------------END CERTIFICATES TEXTS---------------------------*/

    /*------------------------START USER CERTIFICATES---------------------------*/

    case actionTypes.GET_MICROCERTIFICATES_SUCCESS:

        return Object.assign({}, state, {
          isLoading: false,
          usercertificates: action.payload.usercertificates,

      });

      case actionTypes.DEL_MICROCERTIFICATES_SUCCESS:

        return Object.assign({}, state, {
          isLoading: false,
          usercertificates: action.payload.usercertificates,

      });

/*------------------------END USER CERTIFICATES---------------------------*/

        default:
            return state;
    }





}

export default microCategoriesReducer;
