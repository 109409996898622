import { fork, actionChannel, put, take, call, race } from 'redux-saga/effects';
/*import { delay } from 'redux-saga'
import { Link } from 'react-router-dom';
import {
  Nav,
  NavbarToggler,
  NavbarBrand,
} from 'reactstrap';import React, { Component } from 'react';
*/
import Swal from 'sweetalert2';

import * as actionTypes from './action';
import * as loginActions from '../Login/action';
const settings = require('../../settings');

/**
 * Saga watchers
 */
export function *watchGetClips() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_CLIPS_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(getClips, payload);
    }
}

export function *watchGetSingleClip() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_CLIPSINGLE_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(getClip, payload);
    }
}

export function *watchSyncClip() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.CLIP_SYNC_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(syncClip, payload);
    }
}

export function *watchTranscribeClip() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.TRANSCRIPTION_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(transcribeClip, payload);
    }
}

export function *watchTranslateClip() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.TRANSLATION_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(translateClip, payload);
    }
}

export function *watchUpdateClipSubtitles() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.UPDATE_SUBTITLES_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(updateClipSubtitles, payload);
    }
}

export function *watchBurnClipSubtitles() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.BURN_SUBTITLES_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(burnClipSubtitles, payload);
    }
}

export function *watchCreateClip() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.CLIPS_CREATE_JOOBSTERDB);
    while (payload = yield take(reqChannel)) {
        yield fork(createClip, payload);
    }
}

/**
 * http fetch method
 * @param {*} request
 * @param {*} method
 * @param {*} authToken
 */
const httpHandler = (request, method, authToken, body) => {
    let jsonBody = null;
    console.log('httphandler body is: ', body)
    if (['GET', 'DELETE', 'POST', 'PUT'].indexOf(method) > 0) {
        jsonBody = (body) ? JSON.stringify(body) : JSON.stringify({});
    } 

    
    

    return fetch(request,
    {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + authToken
        },
        body: jsonBody
    })
    .then((response) => response.json())
    .then((response) => response);
};

/**
 * Fetches all clips from server
 * @param {*} authToken
 * @param {*} groupId
 * @param {*} page
 */
function *getClips(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/supergroup/' + action.payload.sgid +  '/clip';
        const result = yield call(httpHandler, request, 'GET', action.payload.authToken, null);
        if (result.status == 'ok') {
            yield put({ type: actionTypes.GET_CLIPS_SUCCESS, payload: { clips: result.clips, status: result.status, page: action.payload.page, processLevel: action.payload.processLevel } });
        } else {
            // authorization error
            Swal.fire({
              title: 'Abgemeldet!',
              position: 'center',
              text: 'Sie wurden vom System abgemeldet! Entweder ist Ihre Sitzung abgelaufen, oder ein anderer Benutzer hat sich mit den gleichen Benutzerdaten angemeldet!',
              animation: true,
              customClass: {
                popup: 'animated data'
              },
              type: 'error',
              showConfirmButton: true,
              //timer: 3000
              confirmButtonText: 'Fortfahren'
            })
            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload:  {error: error }});
    }
}

/**
 * Update a single clip
 * @param {*} authToken
 * @param {*} groupId
 * @param {*} clip
 */
function *syncClip(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/group/' + action.payload.groupId +  '/clip/' + action.payload.clip.id;
        const result = yield call(httpHandler, request, 'PUT', action.payload.authToken, action.payload.clip);
        console.log('clip in syncclip is: ', result)
        if (result.status == 'ok') {
            yield put({ type: actionTypes.UPDATECLIP_LOCALSTORE, payload: { clip: result.clip } });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    }
}

/**
 * Get a single clip
 * @param {*} authToken
 * @param {*} groupId
 * @param {*} clip
 */
 function *getClip(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/supergroup/' + action.payload.groupId +  '/clip/' + action.payload.clip.id;
        const result = yield call(httpHandler, request, 'GET', action.payload.authToken, action.payload.clip);
        console.log('clip in syncclip is: ', result)
        if (result.status == 'ok') {
            yield put({ type: actionTypes.UPDATECLIP_LOCALSTORE, payload: { clip: result.clip } });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    }
}

/**
 * clip audio to text transcription
 * @param {*} authToken
 * @param {*} clipId
 * @param {*} lang
 */
function *transcribeClip(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/clip/' + action.payload.clipId +  '/transcribe/' + action.payload.lang;
        let result = yield call(httpHandler, request, 'PUT', action.payload.authToken);
        if (result.status == 'ok') {
            console.log('tanscribeclip: ', result)
            yield put({ type: actionTypes.UPDATECLIP_LOCALSTORE, payload: { clip: result.clip } });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    }
}

/**
 * Multilanguage translation
 * @param {*} action
 */
function *translateClip(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/clip/' + action.payload.clipId +  '/translate/' + action.payload.lang;
        let result = yield call(httpHandler, request, 'PUT', action.payload.authToken);
        if (result.status == 'ok') {
            yield put({ type: actionTypes.UPDATECLIP_LOCALSTORE, payload: { clip: result.clip } });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    }
}

/**
 * Update language specif subtitles
 * @param {*} action
 */
function *updateClipSubtitles(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/clip/' + action.payload.clipId +  '/text/' + action.payload.lang;
        let result = yield call(httpHandler, request, 'PUT', action.payload.authToken, action.payload.segments);
        if (result.status == 'ok') {
            yield put({ type: actionTypes.UPDATECLIP_LOCALSTORE, payload: { clip: result.clip } });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    }
}

/**
 * Burns subtitles into mp4 and uploads to cloud
 * @param {*} action
 */
function *burnClipSubtitles(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/clip/' + action.payload.clipId +  '/burnsubtitles';
        let result = yield call(httpHandler, request, 'POST', action.payload.authToken);
        if (result.status == 'ok') {
            yield put({ type: actionTypes.UPDATECLIP_LOCALSTORE, payload: { clip: result.clip } });
            console.log('started burning')
        } else {
            // authorization error
            console.log('error in burning')

        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    }
}

/**
 * Creates a new clip
 * @param {*} action
 */
function *createClip(action) {
    try {
        const request = settings.api_base_path + settings.api_admin_path + '/user/' + action.payload.userid +  '/clip';
        let result = yield call(httpHandler, request, 'POST', action.payload.authToken,action.payload.body);
        console.log('action is creatclip: ', result)
        if (result.status == 'ok') {
            yield put({ type: actionTypes.CLIPS_CREATE, payload: { clip: result.clip } });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    }
}




