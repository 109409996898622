import { fork, actionChannel, put, take, call } from 'redux-saga/effects';
import * as adminActions from './action';
//import Swal from 'sweetalert2';

const settings = require('../../../settings');


/**
 * Saga watchers
 */
export function* watchGetWhatsappData() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_WHATSAPPDATA_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(getWhatsappList, payload);
    }
}

export function* watchSetWhatsappData() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.SET_WHATSAPPDATA_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(setWhatsappData, payload);
    }
}

export function* watchGetWhatsappProfiles() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_WHATSAPPPROFILE_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(getWhatsappProfileList, payload);
    }
}

export function* watchSetWhatsappProfiles() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.SET_WHATSAPPPROFILE_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(setWhatsappProfile, payload);
    }
}

export function* watchDelWhatsappProfiles() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.DEL_WHATSAPPPROFILE_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(removeWhatsappProfile, payload);
    }
}


const getWhatsappDataFromjoobster = async (payload) => {


    console.log('this is the microcategory payload object: ', payload)
    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/whatsapp/"+ payload.id +"/hierarchy", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },

    }).then((res) => res.json())
        .then((data) => data);


}




function* getWhatsappList(action) {
    try {
        const result = yield call(getWhatsappDataFromjoobster, action.payload)
        console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXhierarchy callXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX: ', result)

        if (result.status == "ok") {
            yield put({ type: adminActions.GET_WHATSAPPDATA_SUCCESS, payload: result.hierarchy });
        } else {
            yield put({ type: adminActions.GET_WHATSAPPDATA_ERROR, payload: {}, error: true });
        }

    } catch (error) {
        yield put({ type: adminActions.GET_WHATSAPPDATA_ERROR, payload: error, error: true });
    }
}


const getWhatsappProfilesFromjoobster = (payload) => {


    console.log('this is the microcategory payload object: ', payload)
    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/whatsapp", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },

    }).then((res) => res.json())
        .then((data) => data);


}




function* getWhatsappProfileList(action) {
    try {
        const result = yield call(getWhatsappProfilesFromjoobster, action.payload)
        console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXhierarchy callXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX: ', result)

        if (result.status == "ok") {
            yield put({ type: adminActions.GET_WHATSAPPPROFILE_SUCCESS, payload: result.profiles });
        } else {
            yield put({ type: adminActions.GET_WHATSAPPPROFILE_ERROR, payload: {}, error: true });
        }

    } catch (error) {
        yield put({ type: adminActions.GET_WHATSAPPPROFILE_ERROR, payload: error, error: true });
    }
}



const setWhatsappDataTojoobster = (payload) => {

    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/whatsapp/" +payload.id+ "/hierarchy", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },
        body: JSON.stringify(payload.body),
    }).then((res) => res.json())
        .then((data) => data);


}




function* setWhatsappData(action) {
    try {
        const result = yield call(setWhatsappDataTojoobster, action.payload)

        //console.log('error feedback in redux-store is. ', result)
        if (result.status == "ok") {
           
            yield put({ type: adminActions.SET_WHATSAPPDATA_SUCCESS, payload: result.hierarchy } );
        } else {
            yield put({ type: adminActions.SET_WHATSAPPDATA_ERROR, payload: { savestatus: true }} );
        }

    } catch (error) {
        yield put({ type: adminActions.SET_WHATSAPPDATA_ERROR, payload: { savestatus: true} });
    }
}



const setWhatsappProfileTojoobster = (payload) => {


    console.log('post - json is: ', payload.body)
    return fetch(
        payload.method === "POST" ? settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/whatsapp"
        : settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/whatsapp/" + payload.id
        , {
        method: payload.method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },
        body: JSON.stringify(payload.body),
    }).then((res) => res.json())
        .then((data) => data);


}




function* setWhatsappProfile(action) {

    try {
        const result = yield call(setWhatsappProfileTojoobster, action.payload)

        if (result.status == "ok") {
            console.log('Payload is now: ', result)
           
            yield put({ type: adminActions.SET_WHATSAPPPROFILE_SUCCESS, payload: {profile: result.profile, method:action.payload.method, savestatus: false} } );
        } else {
            yield put({ type: adminActions.SET_WHATSAPPPROFILE_ERROR, payload: { savestatus: false }} );
        }

    } catch (error) {
        console.log('error feedback in redux-store is. ', error)

        yield put({ type: adminActions.SET_WHATSAPPPROFILE_ERROR, payload: { savestatus: false} });
    }
}



const removeWhatsappProfileFromjoobster = (payload) => {


    console.log('post - json is: ', payload.body)
    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/whatsapp/" +payload.id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },
        //body: JSON.stringify(payload.body),
    }).then((res) => res.json())
        .then((data) => data);


}




function* removeWhatsappProfile(action) {
    try {
        const result = yield call(removeWhatsappProfileFromjoobster, action.payload)

        //console.log('error feedback in redux-store is. ', result)
        if (result.status == "ok") {
            console.log('Payload is now: ', result)
           
            yield put({ type: adminActions.DEL_WHATSAPPPROFILE_SUCCESS, payload: {profiles: result.profiles, savestatus: false} } );
        } else {
            yield put({ type: adminActions.DEL_WHATSAPPPROFILE_ERROR, payload: { savestatus: false }} );
        }

    } catch (error) {
        yield put({ type: adminActions.DEL_WHATSAPPPROFILE_ERROR, payload: { savestatus: false} });
    }
}




