import * as actionTypes from './action';


const initialState = {
    isLoading: false,
    error: null,
    updateDate: null,
    status: undefined, 
    sounds: []
    
};

const MusicReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MUSIC_SUCCESS: {


            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                status: action.payload.status, 
                sounds: action.payload.sounds
            });
        }
        case actionTypes.GET_MUSIC_REQUEST: {
           

            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                sounds: state.sounds
            });
        }
        case actionTypes.MUSIC_ERROR: {
           
            return Object.assign({}, state, {
                isLoading: false,
                status: action.payload.status, 
                error: action.payload.error, 
                settings: state.settings
            });
        }
        default:
            return state;
    }
}




export default MusicReducer;
