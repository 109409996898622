import {fork, actionChannel, put, take, call} from 'redux-saga/effects';

import Swal from 'sweetalert2'
import * as scrapperActions from './action';
import * as loginActions from '../Login/action';

const settings = require('../../settings');
/**
 * Saga watchers
 */
export function *watchScrapperDataFetch() {
    let payload;
    const reqChannel = yield actionChannel(scrapperActions.GET_SERVER_DATA)
    while (payload = yield take(reqChannel)) {
        yield fork(getData, payload);
    }
}

export function *watchScrapperDataUpdate() {
  let payload;
  const reqChannel = yield actionChannel(scrapperActions.UPDATE_COMPANY_SERVER_DATA)
  while (payload = yield take(reqChannel)) {
      yield fork(updateCompanyData, payload);
  }
}

export function *watchScrapperDataCreateCompany() {
  let payload;
  const reqChannel = yield actionChannel(scrapperActions.CREATE_COMPANY_SERVER_DATA)
  while (payload = yield take(reqChannel)) {
      yield fork(createCompanyData, payload);
  }
}


export function *watchScrapperDataFairDeleteCompany() {
  let payload;
  const reqChannel = yield actionChannel(scrapperActions.FAIR_DELETE_COMPANY_SERVER_DATA)
  while (payload = yield take(reqChannel)) {
      yield fork(deleteCompanyData, payload);
  }
}


/**
 * Auth Signin endpoint
 * @param {*} payload
 */
const getScrapperData = async (payload) => {

  //5cdc1f191bb6981def0d23fd
    return fetch( settings.api_scrapper_path + '/fair/'+payload.id+'/company',
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.auth.sessionId
          },
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *getData(action) {
    try {
        console.log('action is: ', action)
        const result = yield call(getScrapperData, action.payload)
        yield put({ type: scrapperActions.GET_SERVER_DATA_REQUEST, payload: { status: result.status } });


        if (result.status == 'ok') {
            yield put({ type: scrapperActions.GET_SERVER_DATA_SUCCESS, payload: result.companies });

        } else {
            console.log('Fetching error! in Scrapper SAGA')
            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },
                type: 'error',
                showConfirmButton: false,
                timer: 1500
                //confirmButtonText: 'Cool'
              })

            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }

    } catch(error) {
        console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
        Swal.fire({
            title: 'Error!',
            position: 'center',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })
        yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
    }
}


const updateCompanyScrapperData = async (payload) => {

  return fetch(settings.api_scrapper_path + '/company/' + payload.company.id,
  {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.auth.sessionId
        },
      body: JSON.stringify(payload.company)
  })
  .then((response) => response.json())
  .then((response) => response);
};

function *updateCompanyData(action) {
  try {
      console.log('action in update server data is: ', action)
      const result = yield call(updateCompanyScrapperData, action.payload)
      yield put({ type: scrapperActions.UPDATE_COMPANY_SERVER_DATA_REQUEST, payload: { status: result.status } });


      if (result.status == 'ok') {
          yield put({ type: scrapperActions.UPDATE_COMPANY_SERVER_DATA_SUCCESS, payload: result.company });

      } else {
          console.log('Update error! in Scrapper SAGA')
          Swal.fire({
              title: 'Error!',
              position: 'center',
              text: result.status,
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              type: 'error',
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })

          yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
      }

  } catch(error) {
      console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
      Swal.fire({
          title: 'Error!',
          position: 'center',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          text: error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
          //confirmButtonText: 'Cool'
        })
      yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
  }
}


const createCompanyScrapperData = async (payload) => {

  return fetch( settings.api_scrapper_path + '/company',
  {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.auth.sessionId
        },
      body: JSON.stringify(payload.company)
  })
  .then((response) => response.json())
  .then((response) => response);
};

function *createCompanyData(action) {
  try {
      console.log('action in create server data is: ', action)
      const result = yield call(createCompanyScrapperData, action.payload)
      yield put({ type: scrapperActions.CREATE_COMPANY_SERVER_DATA_REQUEST, payload: { status: result.status } });


      if (result.status == 'ok') {
          yield put({ type: scrapperActions.CREATE_COMPANY_SERVER_DATA_SUCCESS, payload: result.company });

      } else {
          console.log('Create error! in Scrapper SAGA')
          Swal.fire({
              title: 'Error!',
              position: 'center',
              text: result.status,
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              type: 'error',
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })

          yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
      }

  } catch(error) {
      console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
      Swal.fire({
          title: 'Error!',
          position: 'center',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          text: error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
          //confirmButtonText: 'Cool'
        })
      yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
  }
}



const deleteCompanyScrapperData = async (payload) => {

  return fetch( settings.api_scrapper_path + '/company/'+payload.id,
  {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.auth.sessionId
        },
  })
  .then((response) => response.json())
  .then((response) => response);
};

function *deleteCompanyData(action) {
  try {
      console.log('action in delete server data is: ', action)
      const result = yield call(deleteCompanyScrapperData, action.payload)
      yield put({ type: scrapperActions.FAIR_DELETE_COMPANY_SERVER_DATA_REQUEST, payload: { status: result.status } });


      if (result.status == 'ok') {
          yield put({ type: scrapperActions.FAIR_DELETE_COMPANY_SERVER_DATA_SUCCESS,  payload: {} });

      } else {
          console.log('Delete error! in Scrapper SAGA')
          Swal.fire({
              title: 'Error!',
              position: 'center',
              text: result.status,
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              type: 'error',
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })

          yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
      }

  } catch(error) {
      console.log('ERROR IN SAGA DELETE WITH SCRAPPER IS: ', error)
      Swal.fire({
          title: 'Error!',
          position: 'center',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          text: error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
          //confirmButtonText: 'Cool'
        })
      yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
  }
}
