
export const GET_LINKEDIN_LOGIN_CODE = 'GET_LINKEDIN_LOGIN_CODE';
export const GET_LINKEDIN_LOGIN_CODE_SUCCESS = 'GET_LINKEDIN_LOGIN_CODE_SUCCESS';
export const GET_LINKEDIN_LOGIN_CODE_REQUEST = 'GET_LINKEDIN_LOGIN_CODE_REQUEST';
export const SET_LINKEDIN_LOGIN_CODE = 'SET_LINKEDIN_LOGIN_CODE';

export const GET_LINKEDIN_LOGIN_TOKEN = 'GET_LINKEDIN_LOGIN_TOKEN';
export const GET_LINKEDIN_LOGIN_TOKEN_SUCCESS = 'GET_LINKEDIN_LOGIN_TOKEN_SUCCESS';
export const GET_LINKEDIN_LOGIN_TOKEN_REQUEST = 'GET_LINKEDIN_LOGIN_TOKEN_REQUEST';
export const GET_LINKEDIN_LOGIN_TOKEN_VALIDITY = 'GET_LINKEDIN_LOGIN_TOKEN_VALIDITY';

export const GET_LINKEDIN_ORGANIZATIONS = "GET_LINKEDIN_ORGANIZATIONS"
export const GET_LINKEDIN_ORGANIZATIONS_REQUEST = "GET_LINKEDIN_ORGANIZATIONS_REQUEST"

export const RESET_LOCAL_STORE_SOCIALSHARE = 'RESET_LOCAL_STORE_SOCIALSHARE';

export const SET_FACEBOOK_LOGINDATA = "SET_FACEBOOK_LOGINDATA";
export const SET_YOUTUBE_LOGINDATA = "SET_YOUTUBE_LOGINDATA";
export const SET_TIKTOK_LOGINDATA = "SET_TIKTOK_LOGINDATA";



export const setFacebookLoginData = (payload) => ({
  type: SET_FACEBOOK_LOGINDATA, 
  payload
})
export const setTikTokLoginData = (payload) => ({
  type: SET_TIKTOK_LOGINDATA, 
  payload
})

export const setYoutubeLoginData = (payload) => ({
  type: SET_YOUTUBE_LOGINDATA, 
  payload
})

export const getLinkedInOrganizations =(payload) => ({
  type: GET_LINKEDIN_ORGANIZATIONS,
  payload
})


export const getLinkedInTokenValidity =(payload) => ({
  type: GET_LINKEDIN_LOGIN_TOKEN_VALIDITY,
  payload
})


export const resetLocalStore = (payload) => ({
  type: RESET_LOCAL_STORE_SOCIALSHARE,
  payload
})

export const getLinkedInToken = (payload) => ({
  type: GET_LINKEDIN_LOGIN_TOKEN,
  payload
})

export const getLinkedInTokenRequest = (payload) => ({
  type: GET_LINKEDIN_LOGIN_TOKEN_REQUEST,
  payload
})

export const getLinkedInTokenSuccess = (payload) => ({
  type: GET_LINKEDIN_LOGIN_TOKEN_SUCCESS,
  payload
})

export const getLinkedInCode = (payload) => ({
  type: GET_LINKEDIN_LOGIN_CODE,
  payload
})

export const getLinkedInCodeRequest = (payload) => ({
  type: GET_LINKEDIN_LOGIN_CODE_REQUEST,
  payload
})

export const getLinkedInCodeSuccess = (payload) => ({
  type: GET_LINKEDIN_LOGIN_CODE_SUCCESS,
  payload
})

export const setLinkedInCode = (payload) => ({
  tpye: SET_LINKEDIN_LOGIN_CODE,
  payload
})
