import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'

import authReducer from '../views/Login/reducer';

import clipReducer from '../views/Clips/reducer';
import categoryReducer from '../views/Clips/reducerCategories';
import groupReducer from '../views/Clips/reducerGroups';
import templateReducer from '../views/Administration/Templatesectionsmanagement/reducer'
import superGroupReducer from '../views/Clips/reducerSuperGroups';

import userReducer from '../views/Administration/Gruppenverwaltung/reducer';
import microCategoriesReducer from '../views/Administration/Microlearningverwaltung/reducer';
import whatsappReducer from '../views/Job/whatsapp/reducer';

import categoryReducerForSupergroup from '../views/Administration/Kategorienmanagement/reducer';
import sectionsReducerForSupergroup from '../views/Administration/Portalsectionsmanagement/reducer';

import systemStateReducer from '../views/Dashboard/reducer';
import scrapperReducer from '../views/Scrapper/reducer';
import companyscrapperReducer from '../views/Scrapper/Companies/reducer'
import campaignscrapperReducer from '../views/Scrapper/Campaigns/reducer'
import hostscrapperReducer from '../views/Scrapper/Hosts/reducer'
import musicReducer from '../views/Music/reducer'

import changeState from '../components/reducer'

import trainingContentReducer from '../views/Training/reducer';
import SocialShareReducer from '../views/Socialshare/reducer';

export default combineReducers({
    routing: routerReducer,
    authReducer,
    clipReducer,
    categoryReducer,
    groupReducer,
    superGroupReducer,
    userReducer,
    microCategoriesReducer,
    categoryReducerForSupergroup,
    systemStateReducer,
    scrapperReducer,
    companyscrapperReducer,
    campaignscrapperReducer,
    hostscrapperReducer,
    sectionsReducerForSupergroup,
    musicReducer,
    changeState,
    templateReducer,
    whatsappReducer,
    trainingContentReducer,
    SocialShareReducer,
});


