/*import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
*/

//import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
//import './polyfill'
import React, { Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, {persistor}  from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider, withSnackbar } from 'notistack';

//import { useTranslation } from 'react-i18next';
import './i18n'
import { makeStyles } from '@mui/styles';

import {  ThemeProvider , createTheme} from '@mui/material/styles';
import theme from './theme'

import 'react-select-search/style.css'



const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  }
}));



function render (){



    ReactDOM.render(
    (
<>
{
  /*
  <link rel="stylesheet" href="/selectsearch.css" media="all" />
  */
}


        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider  maxSnack={5}>
          <Suspense fallback={<div>Loading</div>}>
            <ThemeProvider theme={theme} >
                <App />
                </ThemeProvider>
          </Suspense>

            </SnackbarProvider>
          </PersistGate>
        </Provider>
        </>
      )
    , document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();

render();
store.subscribe(render);
