
export const GET_CATEGORIESFORSUPERGROUP_ERROR = 'GET_CATEGORIESFORSUPERGROUP_ERROR'
export const GET_CATEGORIESFORSUPERGROUP_SUCCESS = 'GET_CATEGORIESFORSUPERGROUP_SUCCESS'
export const GET_CATEGORIESFORSUPERGROUP_REQUEST = 'GET_CATEGORIESFORSUPERGROUP_REQUEST'
export const ADD_CATEGORYFORSUPERGROUP_TOLOCALSTORE = 'ADD_CATEGORYFORSUPERGROUP_TOLOCALSTORE'
export const DEL_CATEGORYFORSUPERGROUP_FROMLOCALSTORE = 'DEL_CATEGORYFORSUPERGROUP_FROMLOCALSTORE'


export const SET_CATEGORIESFORSUPERGROUP_ERROR = 'SET_CATEGORIESFORSUPERGROUP_ERROR'
export const SET_CATEGORIESFORSUPERGROUP_SUCCESS = 'SET_CATEGORIESFORSUPERGROUP_SUCCESS'
export const SET_CATEGORIESFORSUPERGROUP_REQUEST = 'SET_CATEGORIESFORSUPERGROUP_REQUEST'



export const getCategoriesFORSUPERGROUPRequest = (payload) => ({
    type: GET_CATEGORIESFORSUPERGROUP_REQUEST, 
    payload
})


export const getCategoriesFORSUPERGROUPSuccess = (payload) => ({
    type: GET_CATEGORIESFORSUPERGROUP_SUCCESS,
    payload
})

export const getCategoriesFORSUPERGROUPError = (error) => ({
    type: GET_CATEGORIESFORSUPERGROUP_ERROR,
    error
})


export const setCategoriesFORSUPERGROUPRequest = (payload) => ({
    type: SET_CATEGORIESFORSUPERGROUP_REQUEST, 
    payload
})


export const setCategoriesFORSUPERGROUPSuccess = (payload) => ({
    type: SET_CATEGORIESFORSUPERGROUP_SUCCESS,
    payload
})

export const setCategoriesFORSUPERGROUPError = (error) => ({
    type: SET_CATEGORIESFORSUPERGROUP_ERROR,
    error
})


export const delCategoryFORSUPERGROUPFromLocalStore = (payload) => ({
    type: DEL_CATEGORYFORSUPERGROUP_FROMLOCALSTORE,
    payload
})

export const addCategoryFORSUPERGROUPToLocalStore = (payload) => ({
    type: ADD_CATEGORYFORSUPERGROUP_TOLOCALSTORE,
    payload
})