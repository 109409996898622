/**
 * Music action types
 */
export const GET_MUSIC_REQUEST = 'GET_MUSIC_REQUEST';
export const GET_MUSIC_SUCCESS = 'GET_MUSIC_SUCCESS';


export const MUSIC_ERROR = 'MUSIC_ERROR';


export const getMusicRequest = (payload) => ({
    type: GET_MUSIC_REQUEST,
    payload
});


export const getMusicSuccess = (payload) => ({
    type: GET_MUSIC_SUCCESS,
    payload
});


export const musicError = (error) => ({
    type: MUSIC_ERROR,
    error
});

