import * as actionTypes from './action';

const initialState = {

    sidebarShow: true,

};

const changeState = (state = initialState, {type, ...rest}) => {
  switch (type) {
    case actionTypes.CHANGESTATEREDUCER_SET:

      return { ...state, ...rest }
    default:
      return state
  }
}
export default changeState;
