import {fork, actionChannel, put, take, call} from 'redux-saga/effects';

import Swal from 'sweetalert2'
import * as scrapperActions from './action';
import * as loginActions from '../../Login/action';

const settings = require('../../../settings');
/**
 * Saga watchers
 */
export function *watchScrapperDataCampaignFetch() {
    let payload;
    const reqChannel = yield actionChannel(scrapperActions.CAMPAIGN_GET_SERVER_DATA)
    while (payload = yield take(reqChannel)) {
        yield fork(getData, payload);
    }
}

export function *watchScrapperDataCampaignUpdate() {
  let payload;
  const reqChannel = yield actionChannel(scrapperActions.CAMPAIGN_UPDATE_SERVER_DATA)
  while (payload = yield take(reqChannel)) {
      yield fork(updateData, payload);
  }
}

export function *watchScrapperDataCampaignCreateCompany() {
  let payload;
  const reqChannel = yield actionChannel(scrapperActions.CAMPAIGN_CREATE_SERVER_DATA)
  while (payload = yield take(reqChannel)) {
      yield fork(createData, payload);
  }
}


export function *watchScrapperDataCampaignDeleteCompany() {
  let payload;
  const reqChannel = yield actionChannel(scrapperActions.CAMPAIGN_DELETE_SERVER_DATA)
  while (payload = yield take(reqChannel)) {
      yield fork(deleteData, payload);
  }
}


/**
 * Auth Signin endpoint
 * @param {*} payload
 */
const getScrapperData = async (payload) => {

    return fetch( settings.api_scrapper_path + '/supergroup/'+ payload.sgid + '/campaign',
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.auth.sessionId
          },
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *getData(action) {
    try {
        console.log('action in scrapper-companies->saga is: ', action)
        const result = yield call(getScrapperData, action.payload)
        yield put({ type: scrapperActions.CAMPAIGN_GET_SERVER_DATA_REQUEST, payload: { status: result.status } });


        if (result.status == 'ok') {
            yield put({ type: scrapperActions.CAMPAIGN_GET_SERVER_DATA_SUCCESS, payload: result.campaigns });

        } else {
            console.log('Fetching error! in Company-Scrapper SAGA')
            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: result.status,
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },
                type: 'error',
                showConfirmButton: false,
                timer: 1500
                //confirmButtonText: 'Cool'
              })

            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }

    } catch(error) {
        console.log('ERROR IN SAGA WITH COMPANY-SCRAPPER IS: ', error)
        Swal.fire({
            title: 'Error!',
            position: 'center',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: error,
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })
        yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
    }
}


const updateFetchData = async (payload) => {

  console.debug('saga in scrapper-companies: ', payload)
  return fetch(settings.api_scrapper_path + '/supergroup/' + payload.sgid + '/campaign/'+payload.campaignid,
  {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.auth.sessionId
        },
      body: JSON.stringify(payload.data)
  })
  .then((response) => response.json())
  .then((response) => response);
};

function *updateData(action) {
  try {
      console.log('action in update server data is: ', action)
      const result = yield call(updateFetchData, action.payload)
      yield put({ type: scrapperActions.CAMPAIGN_UPDATE_SERVER_DATA_REQUEST, payload: { status: result.status } });


      if (result.status == 'ok') {
          yield put({ type: scrapperActions.CAMPAIGN_UPDATE_SERVER_DATA_SUCCESS, payload: result.campaign });

      } else {
          console.log('Update error! in Scrapper SAGA')
          Swal.fire({
              title: 'Error!',
              position: 'center',
              text: result.status,
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              type: 'error',
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })

          yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
      }

  } catch(error) {
      console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
      Swal.fire({
          title: 'Error!',
          position: 'center',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          text: error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
          //confirmButtonText: 'Cool'
        })
      yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
  }
}


const createScrapperData = async (payload) => {

  return fetch( settings.api_scrapper_path + '/supergroup/'+ payload.sgid + '/campaign',
  {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.auth.sessionId
        },
      body: JSON.stringify(payload.data)
  })
  .then((response) => response.json())
  .then((response) => response);
};

function *createData(action) {
  try {
      console.log('action in create server data is: ', action)
      const result = yield call(createScrapperData, action.payload)
      yield put({ type: scrapperActions.CAMPAIGN_CREATE_SERVER_DATA_REQUEST, payload: { status: result.status } });


      if (result.status == 'ok') {
          yield put({ type: scrapperActions.CAMPAIGN_CREATE_SERVER_DATA_SUCCESS, payload:{ campaign: result.campaign} });

      } else {
          console.log('Create error! in Scrapper SAGA')
          Swal.fire({
              title: 'Error!',
              position: 'center',
              text: result.status,
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              type: 'error',
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })

          yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
      }

  } catch(error) {
      console.log('ERROR IN SAGA WITH SCRAPPER IS: ', error)
      Swal.fire({
          title: 'Error!',
          position: 'center',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          text: error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
          //confirmButtonText: 'Cool'
        })
      yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
  }
}


const deleteScrapperData = async (payload) => {

  return fetch( settings.api_scrapper_path + '/supergroup/'+payload.sgid + '/campaign/'+payload.id,
  {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.auth.sessionId
        },
  })
  .then((response) => response.json())
  .then((response) => response);
};

function *deleteData(action) {
  try {
      console.log('action in delete server data is: ', action)
      const result = yield call(deleteScrapperData, action.payload)
      yield put({ type: scrapperActions.CAMPAIGN_DELETE_SERVER_DATA_REQUEST, payload: { status: result.status } });


      if (result.status == 'ok') {
          yield put({ type: scrapperActions.CAMPAIGN_DELETE_SERVER_DATA_SUCCESS,  payload: {} });

      } else {
          console.log('Delete error! in Scrapper SAGA')
          Swal.fire({
              title: 'Error!',
              position: 'center',
              text: result.status,
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              type: 'error',
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })

          yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
      }

  } catch(error) {
      console.log('ERROR IN SAGA DELETE WITH SCRAPPER IS: ', error)
      Swal.fire({
          title: 'Error!',
          position: 'center',
          animation: true,
          customClass: {
            popup: 'animated tada'
          },
          text: error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
          //confirmButtonText: 'Cool'
        })
      yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
  }
}
