import { fork, actionChannel, put, take, call } from 'redux-saga/effects';
import * as actionTypes from './actionCategories'
const settings = require('../../settings');

/**
 * Saga watchers
 */
export function *watchGetCategories() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_CATEGORIES_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(getCategories, payload);
    }
}

/**
 * Get clips 
 * @param {*} authToken 
 * @param {*} groupId 
 * @param {*} page 
 */
const httpGetCategories = (authToken, groupId) => { 
    return fetch(settings.api_base_path + settings.api_admin_path + '/group/' + groupId +  '/category', 
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + authToken
        }
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *getCategories(action) {
    try {
        let result = yield call(httpGetCategories, action.payload.authToken, action.payload.groupId);
        yield put({ type: actionTypes.GET_CATEGORIES_SUCCESS, payload: { categories: result } });
    } catch(error) {
        yield put({ type: actionTypes.CATEGORIES_ERROR, payload: error, error: true });
    }
}