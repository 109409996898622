import * as actionTypes from './actionCategories';

const initialState = {
    isLoading: false,
    error: null,
    updateDate: null,
    categories:[] 
};

const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                categories: action.payload.categories
            });
        case actionTypes.CATEGORIES_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

export default CategoryReducer;