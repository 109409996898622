/*import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
*/


import logger from 'redux-logger'
import { createStore, applyMiddleware, combineReducers, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist';

import rootReducer from './reducers/index';
import rootSaga from './utils/saga';

const sagaMiddleware = createSagaMiddleware();



const persistConfig = {
  key: 'root',
  storage,
};


const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}




const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, logger),
);



sagaMiddleware.run(rootSaga);

export default store;
export const persistor = persistStore(store);

