/*import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
*/

import React, { Component, Suspense} from 'react'
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import './i18n'


//const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
//const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Login/Login'));


class App extends Component {

  constructor(props) {
    super(props);
   // this.props.auth.languageCode="de"

  }



  changeLanguage = (lang) => {

    this.props.i18n.changeLanguage(lang)
  }


  render() {
    const {t} = this.props;


    return (

      <div>


      <BrowserRouter>
        <div>
{
              this.props.auth && this.props.auth.sessionId ? (
                <div>

                  <Suspense fallback={<div>{t('general.loading')}</div>}>

               <DefaultLayout />
                  </Suspense>
                </div>
              ) :
                (
                  <div>
                    <Suspense fallback={<div>{t('general.loading')}</div>}>
                      <Login />
                    </Suspense>
                  </div>
                )
            }

        </div>

      </BrowserRouter>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer.auth,
        superGroup: state.systemStateReducer.superGroup,
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default  withTranslation()((connect(mapStateToProps, mapDispatchToProps)(App)));

