import { fork, actionChannel, put, take, call } from 'redux-saga/effects';
import * as adminActions from './action';

//import Swal from 'sweetalert2';

const settings = require('../../../settings');


/**
 * Saga watchers
 */
export function* watchGetMicroCategories() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_MICROCATEGORIES_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(getMicroCategoriesList, payload);
    }
}

export function* watchSetMicroCategories() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.SET_MICROCATEGORIES_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(setMicroCategoriesList, payload);
    }
}

export function* watchSetMicroArea() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.SET_MICROAREA_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(setMicroAreaRequest, payload);
    }
}

export function* watchGetMicroAreaList() {
  let payload;
  const reqChannel = yield actionChannel(adminActions.GET_MICROAREA_REQUEST)
  while (payload = yield take(reqChannel)) {

      yield fork(getMicroAreasList, payload);
  }
}

/*-------------------------WATCH TRAININGSESSION BEGIN--------------------------------------*/
export function* watchGetTrainingsession() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_TRAININGSESSION_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTS, payload);
    }
}

export function* watchPostTrainingsession() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.POST_TRAININGSESSION_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTS, payload);
    }
}

export function* watchUpdateTrainingsession() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.PUT_TRAININGSESSION_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTS, payload);
    }
}

export function* watchDeleteTrainingsession() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.DEL_TRAININGSESSION_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTS, payload);
    }
}
/*-------------------------WATCH TRAININGSESSION END--------------------------------------*/

/*-------------------------WATCH TRAININGGROUP BEGIN--------------------------------------*/
export function* watchGetTraininggroup() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_TRAININGGROUP_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTG, payload);
    }
}

export function* watchPostTraininggroup() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.POST_TRAININGGROUP_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTG, payload);
    }
}

export function* watchUpdateTraininggroup() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.PUT_TRAININGGROUP_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTG, payload);
    }
}

export function* watchDeleteTraininggroup() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.DEL_TRAININGGROUP_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerTG, payload);
    }
}
/*-------------------------WATCH TRAININGGROUP END--------------------------------------*/

/*-------------------------WATCH CERTIFICATE BEGIN--------------------------------------*/
export function* watchGetCertificate() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_CERTIFICATE_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerCT, payload);
    }
}

export function* watchPostCertificate() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.POST_CERTIFICATE_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerCT, payload);
    }
}

export function* watchUpdateCertificate() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.PUT_CERTIFICATE_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerCT, payload);
    }
}

export function* watchDeleteCertificate() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.DEL_CERTIFICATE_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerCT, payload);
    }
}
/*-------------------------WATCH CERTIFICATE END--------------------------------------*/


/*-------------------------WATCH OPTIONS BEGIN--------------------------------------*/
export function* watchGetOptions() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_MICROOPTIONS_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerMO, payload);
    }
}

export function* watchPostOptions() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.POST_MICROOPTIONS_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerMO, payload);
    }
}
/*-------------------------WATCH OPTIONS END--------------------------------------*/

/*-------------------------WATCH MICRO CERTIFICATES BEGIN--------------------------------------*/
export function* watchGetMicroCertificates() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_MICROCERTIFICATES_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerMC, payload);
    }
}

export function* watchDelMicroCertificates() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.DEL_MICROCERTIFICATES_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandlerMC, payload);
    }
}
/*-------------------------WATCH MICRO CERTIFICATES END--------------------------------------*/

/*-------------------------WATCH AUDIT BEGIN--------------------------------------*/
export function* watchGetAudits() {
  let payload;
  const reqChannel = yield actionChannel(adminActions.GET_AUDIT_REQUEST)
  while (payload = yield take(reqChannel)) {

      yield fork(universalSagaHandlerAudit, payload);
  }
}
/*-------------------------WATCH AUDIT END--------------------------------------*/

const getMicroAreasFromjoobster = async (payload) => {


  console.log('this is the microcategory payload object: ', payload)
  return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/traininghierarchy", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.authToken
      },

  }).then((res) => res.json())
      .then((data) => data);


}








function* getMicroAreasList(action) {
  try {
      const result = yield call(getMicroAreasFromjoobster, action.payload)
      console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXmicroarea callXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX: ', result)

      if (result.status == "ok") {
          yield put({ type: adminActions.GET_MICROAREA_SUCCESS, payload: result.traininghierarchies });
      } else {
          yield put({ type: adminActions.GET_MICROAREA_ERROR, payload: {}, error: true });
      }

  } catch (error) {
      yield put({ type: adminActions.GET_MICROAREA_ERROR, payload: error, error: true });
  }
}



const setMicroAreaToJoobster = async (payload) => {


    console.log('post - json is: ', payload.body)
    return fetch(
        payload.method === "POST" ? settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/traininghierarchy"
        : settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/traininghierarchy/" + payload.id
        , {
        method: payload.method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },
        body: JSON.stringify(payload.body),
    }).then((res) => res.json())
        .then((data) => data);


}




function* setMicroAreaRequest(action) {

    try {
        const result = yield call(setMicroAreaToJoobster, action.payload)

        if (result.status == "ok") {
            console.log('Payload is now: ', result)

            yield put({ type: adminActions.SET_MICROAREA_SUCCESS,  payload: { traininghierarchies: result.traininghierarchies, method: action.payload.method, currentid: action.payload.id ? action.payload.id : null} } );
        } else {
            yield put({ type: adminActions.SET_MICROAREA_ERROR, payload: { savestatus: false }} );
        }

    } catch (error) {
        console.log('error feedback in redux-store is. ', error)

        yield put({ type: adminActions.SET_MICROAREA_ERROR, payload: { savestatus: false} });
    }
}




const getMicroCategoriesFromjoobster = (payload) => {


    console.log('this is the microcategory payload object: ', payload)
    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/hierarchy", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },

    }).then((res) => res.json())
        .then((data) => data);


}




function* getMicroCategoriesList(action) {
    try {
        const result = yield call(getMicroCategoriesFromjoobster, action.payload)
        console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXhierarchy callXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX: ', result)

        if (result.status == "ok") {
            yield put({ type: adminActions.GET_MICROCATEGORIES_SUCCESS, payload: result.hierarchy });
        } else {
            yield put({ type: adminActions.GET_MICROCATEGORIES_ERROR, payload: {}, error: true });
        }

    } catch (error) {
        yield put({ type: adminActions.GET_MICROCATEGORIES_ERROR, payload: error, error: true });
    }
}



const setMicroCategoriesTojoobster = (payload) => {

    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/hierarchy", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },
        body: payload.body,
    }).then((res) => res.json())
        .then((data) => data);


}




function* setMicroCategoriesList(action) {
    try {
        const result = yield call(setMicroCategoriesTojoobster, action.payload)

      //  console.log('error feedback in redux-store is. ', result)
        if (result.status == "ok") {

            yield put({ type: adminActions.SET_MICROCATEGORIES_SUCCESS, payload: {result, savestatus: false} } );
            yield put({ type: adminActions.GET_MICROCATEGORIES_SUCCESS, payload: result.hierarchy });

        } else {
            yield put({ type: adminActions.SET_MICROCATEGORIES_ERROR, payload: { savestatus: true }} );
        }

    } catch (error) {
        yield put({ type: adminActions.SET_MICROCATEGORIES_ERROR, payload: { savestatus: true} });
    }
}




const universalSagaMethodExecuter = async (payload) => {

    //payload must contain: route, method, authToken
    //if put, delete additionally: contentid


    console.log('universalSagaMethodExecuter - json is: ', payload)

    let mainbody = {
        method: payload.method,
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + payload.authToken
    }}

    if (["POST", "PUT"].includes(payload.method)) mainbody.body = JSON.stringify(payload.body)


    try{

    let f = await fetch(payload.route, mainbody )//.then((res) => res.json()).then((data) => data);

    let f1= f.json()
    let f2 = await f1

    if (payload.hasOwnProperty("enqueueSnackbar")) payload.enqueueSnackbar(payload.t('general.savesuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
    return f2
    }catch(error){
      if (payload.hasOwnProperty("enqueueSnackbar")) payload.enqueueSnackbar(payload.t('general.error'), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
      return null
    }




}


/*------------------------------TRAININGSESSION START----------------------------------*/


function* universalSagaHandlerTS(action) {

    let [erroraction, successindicator] = ""

    if (["POST"].includes(action.payload.method)){
        successindicator = adminActions.POST_TRAININGSESSION_SUCCESS
        erroraction = adminActions.POST_TRAININGSESSION_ERROR
    }else if (["PUT"].includes(action.payload.method)){
        successindicator = adminActions.PUT_TRAININGSESSION_SUCCESS
        erroraction = adminActions.PUT_TRAININGSESSION_ERROR
    }else if (["DELETE"].includes(action.payload.method)){
        successindicator = adminActions.DEL_TRAININGSESSION_SUCCESS
        erroraction = adminActions.DEL_TRAININGSESSION_ERROR
    }else if (["GET"].includes(action.payload.method)){
        successindicator = adminActions.GET_TRAININGSESSION_SUCCESS
        erroraction = adminActions.GET_TRAININGSESSION_ERROR
    }

    try {
        const result = yield call(universalSagaMethodExecuter, action.payload)

        console.log('redux training univesalsaga Training Session is: ', result)

        if (result.status == "ok") {
            console.log('Payload is now: ', result)

            yield put({ type: successindicator, payload: { isLoading: true, trainingsessions: result.trainingsessions }} );
        } else {
            yield put({ type: erroraction, payload: { error: "Error in Saga function universalSagaHandler TrainingSession [Administration->Microlearningverwaltung->saga.js] " + erroraction }} );
        }


    } catch (error) {
       // console.log('Error feedback in redux-store is. ', error)

        yield put({ type: erroraction, payload: { error } });
    }
}

/*------------------------------TRAININGSESSION END----------------------------------*/


/*------------------------------TRAININGGROUP BEGIN----------------------------------*/

function* universalSagaHandlerTG(action) {

    let [erroraction, successindicator] = ""

    if (["POST"].includes(action.payload.method)){
        successindicator = adminActions.POST_TRAININGGROUP_SUCCESS
        erroraction = adminActions.POST_TRAININGGROUP_ERROR
    }else if (["PUT"].includes(action.payload.method)){
        successindicator = adminActions.PUT_TRAININGGROUP_SUCCESS
        erroraction = adminActions.PUT_TRAININGGROUP_ERROR
    }else if (["DELETE"].includes(action.payload.method)){
        successindicator = adminActions.DEL_TRAININGGROUP_SUCCESS
        erroraction = adminActions.DEL_TRAININGGROUP_ERROR
    }else if (["GET"].includes(action.payload.method)){
        successindicator = adminActions.GET_TRAININGGROUP_SUCCESS
        erroraction = adminActions.GET_TRAININGGROUP_ERROR
    }

    try {
        const result = yield call(universalSagaMethodExecuter, action.payload)

        console.log('redux training univesalsaga TrainingGroup is: ', result)

        if (result.status == "ok") {
            console.log('Payload is now: ', result)

            yield put({ type: successindicator, payload: { isLoading: true, traininggroups: result.traininggroups }} );
        } else {
            yield put({ type: erroraction, payload: { error: "Error in Saga function universalSagaHandler Traininggroup [Administration->Microlearningverwaltung->saga.js] " + erroraction }} );
        }


    } catch (error) {
       // console.log('Error feedback in redux-store is. ', error)

        yield put({ type: erroraction, payload: { error } });
    }
}
/*------------------------------TRAININGGROUP END----------------------------------*/

/*------------------------------CERTIFICATE TEXTS BEGIN----------------------------------*/

function* universalSagaHandlerCT(action) {

    let [erroraction, successindicator] = ""

    if (["POST"].includes(action.payload.method)){
        successindicator = adminActions.POST_CERTIFICATE_SUCCESS
        erroraction = adminActions.POST_CERTIFICATE_ERROR
    }else if (["PUT"].includes(action.payload.method)){
        successindicator = adminActions.PUT_CERTIFICATE_SUCCESS
        erroraction = adminActions.PUT_CERTIFICATE_ERROR
    }else if (["DELETE"].includes(action.payload.method)){
        successindicator = adminActions.DEL_CERTIFICATE_SUCCESS
        erroraction = adminActions.DEL_CERTIFICATE_ERROR
    }else if (["GET"].includes(action.payload.method)){
        successindicator = adminActions.GET_CERTIFICATE_SUCCESS
        erroraction = adminActions.GET_CERTIFICATE_ERROR
    }

    try {
        const result = yield call(universalSagaMethodExecuter, action.payload)

        console.log('redux certificate univesalsaga Certificate is: ', result)

        if (result.status == "ok") {
            console.log('Payload is now: ', result)

            yield put({ type: successindicator, payload: { isLoading: true, certificates: result.trainingcertificates }} );
        } else {
            yield put({ type: erroraction, payload: { error: "Error in Saga function universalSagaHandler Certificates [Administration->Microlearningverwaltung->saga.js] " + erroraction }} );
        }


    } catch (error) {
       // console.log('Error feedback in redux-store is. ', error)

        yield put({ type: erroraction, payload: { error } });
    }
}
/*------------------------------CERTIFICATE TEXTS END----------------------------------*/

/*------------------------------MICROOPTIONS (MO) BEGIN----------------------------------*/

function* universalSagaHandlerMO(action) {

    let [erroraction, successindicator] = ""

    if (["POST"].includes(action.payload.method)){
        successindicator = adminActions.POST_MICROOPTIONS_SUCCESS
        erroraction = adminActions.POST_MICROOPTIONS_ERROR
    }else if (["GET"].includes(action.payload.method)){
        successindicator = adminActions.GET_MICROOPTIONS_SUCCESS
        erroraction = adminActions.GET_MICROOPTIONS_ERROR
    }

    try {
        const result = yield call(universalSagaMethodExecuter, action.payload)

        console.log('redux microoptions univesalsaga is: ', result)

        if (result.status == "ok") {
            console.log('Payload is now: ', result)

            yield put({ type: successindicator, payload: { isLoading: true, trainingoptions: result.trainingoptions }} );
        } else {
            yield put({ type: erroraction, payload: { error: "Error in Saga function universalSagaHandler Microoptions [Administration->Microlearningverwaltung->saga.js] " + erroraction }} );
        }


    } catch (error) {
       // console.log('Error feedback in redux-store is. ', error)

        yield put({ type: erroraction, payload: { error } });
    }
}
/*-----------------------------MICROOPTIONS (MO) END----------------------------------*/

/*------------------------------MICRO USER CERTIFICATES (MC) BEGIN----------------------------------*/

function* universalSagaHandlerMC(action) {

    let [erroraction, successindicator] = ""

    if (["DELETE"].includes(action.payload.method)){
        successindicator = adminActions.DEL_MICROCERTIFICATES_SUCCESS
        erroraction = adminActions.DEL_MICROCERTIFICATES_ERROR
    }else if (["GET"].includes(action.payload.method)){
        successindicator = adminActions.GET_MICROCERTIFICATES_SUCCESS
        erroraction = adminActions.GET_MICROCERTIFICATES_ERROR
    }

    try {
        const result = yield call(universalSagaMethodExecuter, action.payload)

        console.log('redux microoptions univesalsaga is: ', result)

        if (result.status == "ok") {
            console.log('Payload is now: ', result)

            yield put({ type: successindicator, payload: { isLoading: true, usercertificates: result.usercertificates }} );
        } else {
            yield put({ type: erroraction, payload: { error: "Error in Saga function universalSagaHandler Micro Certificates [Administration->Microlearningverwaltung->saga.js] " + erroraction }} );
        }


    } catch (error) {
       // console.log('Error feedback in redux-store is. ', error)

        yield put({ type: erroraction, payload: { error } });
    }
}
/*------------------------------MICRO USER CERTIFICATES (MC) END----------------------------------*/

/*------------------------------AUDIT BEGIN----------------------------------*/

function* universalSagaHandlerAudit(action) {

  let [erroraction, successindicator] = ""

  if (["DELETE"].includes(action.payload.method)){
      successindicator = adminActions.DEL_MICROCERTIFICATES_SUCCESS
      erroraction = adminActions.DEL_MICROCERTIFICATES_ERROR
  }else if (["GET"].includes(action.payload.method)){
      successindicator = adminActions.GET_AUDIT_SUCCESS
      erroraction = adminActions.GET_AUDIT_ERROR
  }

  try {
      const result = yield call(universalSagaMethodExecuter, action.payload)

      console.log('redux audit univesalsaga is: ', result)

      if (result.status == "ok") {
          console.log('Payload is now: ', result)

          yield put({ type: successindicator, payload: { isLoading: true, audits: result.audits }} );
      } else {
          yield put({ type: erroraction, payload: { error: "Error in Saga function universalSagaHandler Audit [Administration->Microlearningverwaltung->saga.js] " + erroraction }} );
      }


  } catch (error) {
     // console.log('Error feedback in redux-store is. ', error)

      yield put({ type: erroraction, payload: { error } });
  }
}
/*------------------------------AUDIT END----------------------------------*/
