import * as actionTypes from './action';

const initialState = {
    error: undefined,
    data: [],
    auth: {}
};

const DataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CAMPAIGN_GET_SERVER_DATA:
            return Object.assign({}, state, {
                auth: action.payload.auth
            });
            case actionTypes.CAMPAIGN_GET_SERVER_DATA_SUCCESS:
              console.log('scrapper-companies-reducer-success: ', action.payload)
                return Object.assign({}, state, {
                    data: action.payload
                });

            case actionTypes.CAMPAIGN_UPDATE_LOCAL_STORE:
            let finddata
            if (action.payload.id){
                if (state.data){
                    finddata = state.data.find( data => data.id === action.payload.id)
                    if (finddata){
                        console.log('value is in local store: ', action.payload.value)
                        finddata[action.payload.key] = action.payload.value

                    }
                }
            }
            console.log('UPDATE_LOCAL_STORE: ', finddata)
            return Object.assign({}, state, {
                data: state.data
            });

            case actionTypes.CAMPAIGN_CREATE_SERVER_DATA_SUCCESS:
            console.debug('Scrapper-Companies-Reducer: ', action.payload.campaign)
            return Object.assign({}, state, {
              data: [action.payload.campaign, ...state.data]
            });

            case actionTypes.CAMPAIGN_UPDATE_LOCAL_STORE_NEW:
              console.debug('Scrapper-Companies-Reducer in new Company : ', action.payload.campaign)
            return Object.assign({}, state, {
              data: [action.payload.campaign, ...state.data]
            });

            case actionTypes.CAMPAIGN_DELETE_SERVER_DATA:
              console.debug('Scrapper-Companies-Reducer in delete Company : ', action.payload.id)
              let newobject = state.data.filter( f => f.id !== action.payload.id )
            return Object.assign({}, state, {
              data: newobject
            });


        default:
            return state;
    }
}

export default DataReducer;
