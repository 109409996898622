
export const USER_GET_REQUEST = 'USER_GET_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const DEL_USER_ERROR = 'DEL_USER_ERROR'
export const DEL_USER_SUCCESS = 'DEL_USER_SUCCESS'
export const DEL_USER_REQUEST = 'DEL_USER_REQUEST'
export const ADD_USER_ERROR = 'ADD_USER_ERROR'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const GET_USERFORGROUP_ERROR = 'GET_USERFORGROUP_ERROR'
export const GET_USERFORGROUP_SUCCESS = 'GET_USERFORGROUP_SUCCESS'
export const GET_USERFORGROUP_REQUEST = 'GET_USERFORGROUP_REQUEST'
export const UPDATE_USERFORGROUP = 'UPDATE_USERFORGROUP'
export const UPDATE_USERACTIVE = 'UPDATE_USERACTIVE'
export const UPDATE_USERBACKENDACTIVE = 'UPDATE_USERBACKENDACTIVE'
export const UPDATE_USERMODULEACTIVE = "UPDATE_USERMODULEACTIVE"

export const ALLGROUP_GET_REQUEST = 'ALLGROUP_GET_REQUEST';
export const ALLGROUP_SUCCESS = 'ALLGROUP_SUCCESS';
export const ALLGROUP_ERROR = 'ALLGROUP_ERROR';


export const updateUserForGroup = (payload) => ({
    type: UPDATE_USERFORGROUP,
    payload
})

export const updateUserActive = (payload) => ({
    type: UPDATE_USERACTIVE,
    payload
})

export const updateUserBackendModuleActive = (payload) => ({
    type: UPDATE_USERMODULEACTIVE, 
    payload
})

export const updateUserBackendActive = (payload) => ({
    type: UPDATE_USERBACKENDACTIVE,
    payload
})

export const getUserForGroupRequest = (payload) => ({
    type: GET_USERFORGROUP_REQUEST,
    payload
})


export const getUserForGroupSuccess = (payload) => ({
    type: GET_USERFORGROUP_SUCCESS,
    payload
})

export const getUserForGroupError = (error) => ({
    type: GET_USERFORGROUP_ERROR,
    error
})


export const AddUserRequest = (payload) => ({
    type: ADD_USER_REQUEST,
    payload
})


export const userAddSuccess = (payload) => ({
    type: ADD_USER_SUCCESS,
    payload
})

export const userAddError = (error) => ({
    type: ADD_USER_ERROR,
    error
})



export const DelUserRequest = (payload) => ({
    type: DEL_USER_REQUEST,
    payload
})


export const userDelSuccess = (payload) => ({
    type: DEL_USER_SUCCESS,
    payload
})

export const userDelError = (error) => ({
    type: DEL_USER_ERROR,
    error
})


export const getUserRequest = (payload) => ({
    type: USER_GET_REQUEST,
    payload
})


export const userGetSuccess = (payload) => ({
    type: USER_SUCCESS,
    payload
})

export const userGetError = (error) => ({
    type: USER_ERROR,
    error
})

export const allGroupGETRequest = (payload) => ({
    type: ALLGROUP_GET_REQUEST, 
    payload
})


export const allGroupGETSuccess = (payload) => ({
    type: ALLGROUP_SUCCESS, 
    payload
})

export const allGroupGETError = (error) => ({
    type: ALLGROUP_ERROR, 
    error
})
