import * as actionTypes from './action';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    error: undefined,
    categories: {},
};


const categoryReducerForSupergroup = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_CATEGORIESFORSUPERGROUP_SUCCESS:

            return Object.assign({}, state, {
                isLoading: false,
                categories: [...action.payload]
                
            });
            case actionTypes.SET_CATEGORIESFORSUPERGROUP_SUCCESS:

            Swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Kategorie erfolgreich gespeichert \n',
                showConfirmButton: false,
                timer: 1500
              }) 

            return Object.assign({}, state, {
                isLoading: false,
                categories: [...action.payload]
                
            });

        case actionTypes.DEL_CATEGORYFORSUPERGROUP_FROMLOCALSTORE: 

        var localcategories = new Array();

        if (action.payload.name)
         localcategories = state.categories.filter( f => f.name != action.payload.name);
        


        return Object.assign({}, state, {
          isLoading: false,
          categories:  [...localcategories]                   
      });

      case actionTypes.ADD_CATEGORYFORSUPERGROUP_TOLOCALSTORE: 

      state.categories.push(action.payload.category)

      return Object.assign({}, state, {
        isLoading: false,
        categories:  state.categories                   
    });      

        case actionTypes.GET_CATEGORIESFORSUPERGROUP_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

            case actionTypes.SET_CATEGORIESFORSUPERGROUP_ERROR:

            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Problem beim speichern der Kategorie!',
                type: 'error',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },       
                showConfirmButton: false, 
                timer: 1500
                //confirmButtonText: 'Cool'
              })  

            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

        default:
            return state;
    }
}

export default categoryReducerForSupergroup;