
export const GET_SERVER_DATA = 'GET_SERVER_DATA';
export const GET_SERVER_DATA_SUCCESS = 'GET_SERVER_DATA_SUCCESS';
export const GET_SERVER_DATA_REQUEST = 'GET_SERVER_DATA_REQUEST';

export const UPDATE_LOCAL_STORE = 'UPDATE_LOCAL_STORE';
export const FAIR_DELETE_COMPANY_LOCAL_STORE = "FAIR_DELETE_COMPANY_LOCAL_STORE"
export const FAIR_DELETE_COMPANY_SERVER_DATA = 'FAIR_DELETE_COMPANY_SERVER_DATA';
export const FAIR_DELETE_COMPANY_SERVER_DATA_SUCCESS = 'FAIR_DELETE_COMPANY_SERVER_DATA_SUCCESS'
export const FAIR_DELETE_COMPANY_SERVER_DATA_REQUEST = 'FAIR_DELETE_COMPANY_SERVER_DATA_REQUEST'

export const UPDATE_COMPANY_SERVER_DATA = 'UPDATE_COMPANY_SERVER_DATA';
export const UPDATE_COMPANY_SERVER_DATA_SUCCESS = 'UPDATE_COMPANY_SERVER_DATA_SUCCESS'
export const UPDATE_COMPANY_SERVER_DATA_REQUEST = 'UPDATE_COMPANY_SERVER_DATA_REQUEST'

export const CREATE_COMPANY_SERVER_DATA = 'CREATE_COMPANY_SERVER_DATA';
export const CREATE_COMPANY_SERVER_DATA_SUCCESS = 'CREATE_COMPANY_SERVER_DATA_SUCCESS'
export const CREATE_COMPANY_SERVER_DATA_REQUEST = 'CREATE_COMPANY_SERVER_DATA_REQUEST'


export const AUTH_ERROR = 'AUTH_ERROR'

export const getServerData = (payload) => ({
    type: GET_SERVER_DATA,
    payload
})

export const getServerDataRequest = (payload) => ({
    type: GET_SERVER_DATA_REQUEST,
    payload
})

export const getServerDataSuccess = (payload) => ({
    type: GET_SERVER_DATA_SUCCESS,
    payload
})

export const updateCompanyServerData = (payload) => ({
  type: UPDATE_COMPANY_SERVER_DATA,
  payload
})

export const updateCompanyServerDataRequest = (payload) => ({
  type: UPDATE_COMPANY_SERVER_DATA_REQUEST,
  payload
})

export const updateCompanyServerDataSuccess = (payload) => ({
  type: UPDATE_COMPANY_SERVER_DATA_SUCCESS,
  payload
})

export const createCompanyServerData = (payload) => ({
  type: CREATE_COMPANY_SERVER_DATA,
  payload
})

export const createCompanyServerDataRequest = (payload) => ({
  type: CREATE_COMPANY_SERVER_DATA_REQUEST,
  payload
})

export const createCompanyServerDataSuccess = (payload) => ({
  type: CREATE_COMPANY_SERVER_DATA_SUCCESS,
  payload
})

export const deleteCompanyLocalStore = (payload) => ({
  type: FAIR_DELETE_COMPANY_LOCAL_STORE,
  payload
})

export const deleteCompanyServerData = (payload) => ({
  type: FAIR_DELETE_COMPANY_SERVER_DATA,
  payload
})

export const deleteCompanyServerDataRequest = (payload) => ({
  type: FAIR_DELETE_COMPANY_SERVER_DATA_REQUEST,
  payload
})

export const deleteCompanyServerDataSuccess = (payload) => ({
  type: FAIR_DELETE_COMPANY_SERVER_DATA_SUCCESS,
  payload
})

export const updateLocalStore = (payload) => ({
  type: UPDATE_LOCAL_STORE,
  payload
})

export const authError = (error) => ({
    type: AUTH_ERROR,
    error
})

