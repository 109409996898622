import { fork } from 'redux-saga/effects';

// import all sagas
import { watchSignin, watchPWDReset } from '../views/Login/saga';
import { watchGetSingleClip, watchCreateClip, watchGetClips, watchSyncClip, watchTranscribeClip, watchTranslateClip, watchUpdateClipSubtitles, watchBurnClipSubtitles } from '../views/Clips/saga';
import { watchGetCategories } from '../views/Clips/sagaCategories';
import { watchGetGroups } from '../views/Clips/sagaGroups';
import { watchGetMusic } from '../views/Music/saga';
import { watchGetSuperGroups } from '../views/Clips/sagaSuperGroups';
import { watchGetUser, watchAddUserToGroup, watchGetUserForGroup, watchGetAllGroup} from '../views/Administration/Gruppenverwaltung/saga';
import { watchGetMicroCategories, watchSetMicroCategories, watchGetMicroAreaList, watchSetMicroArea,
   watchDeleteTraininggroup, watchGetTraininggroup, watchPostTraininggroup, watchUpdateTraininggroup,
   watchDeleteTrainingsession, watchGetTrainingsession, watchPostTrainingsession, watchUpdateTrainingsession,
   watchGetCertificate, watchDeleteCertificate, watchPostCertificate, watchUpdateCertificate, watchGetOptions,watchPostOptions, watchDelMicroCertificates, watchGetMicroCertificates,
  watchGetAudits
  } from '../views/Administration/Microlearningverwaltung/saga';
import { watchGetWhatsappData, watchSetWhatsappData, watchGetWhatsappProfiles, watchSetWhatsappProfiles,watchDelWhatsappProfiles} from '../views/Job/whatsapp/saga'
import { watchGetSupergroupCategories, watchSetSupergroupCategories } from '../views/Administration/Kategorienmanagement/saga';
import { watchGetSupergroupTemplatesections, watchSetSupergroupTemplatesections } from '../views/Administration/Templatesectionsmanagement/saga';
import { watchGetSupergroupSections, watchSetSupergroupSections } from '../views/Administration/Portalsectionsmanagement/saga';

import { watchScrapperDataFetch, watchScrapperDataUpdate, watchScrapperDataCreateCompany,watchScrapperDataFairDeleteCompany } from '../views/Scrapper/saga'
import { watchScrapperDataCompanyFetch , watchScrapperDataCompanyUpdate , watchScrapperDataCompanyCreateCompany, watchScrapperDataCompanyDeleteCompany } from '../views/Scrapper/Companies/saga'
import { watchScrapperDataCampaignFetch , watchScrapperDataCampaignUpdate , watchScrapperDataCampaignCreateCompany, watchScrapperDataCampaignDeleteCompany } from '../views/Scrapper/Campaigns/saga'
import { watchScrapperDataCampaignHostsFetch , watchScrapperDataCampaignHostsUpdate , watchScrapperDataCampaignHostsCreateCompany, watchScrapperDataCampaignHostsDeleteCompany } from '../views/Scrapper/Hosts/saga'
import { watchGetTrainingcontent, watchDeleteTrainingcontent, watchPostTrainingcontent, watchUpdateTrainingcontent } from '../views/Training/saga';
import { watchLinkedInCode, watchLinkedInAccessTokenValidity, watchLinkedInGetConnectedOrganizations} from '../views/Socialshare/saga'

export default function* rootSaga() {
 yield [
    fork(watchSignin),
    fork(watchPWDReset),
    fork(watchGetClips),
    fork(watchSyncClip),
    fork(watchTranscribeClip),
    fork(watchTranslateClip),
    fork(watchUpdateClipSubtitles),
    fork(watchBurnClipSubtitles),
    fork(watchGetCategories),
    fork(watchGetGroups),
    fork(watchGetSuperGroups),
    fork(watchGetUser),
    fork(watchAddUserToGroup),
    fork(watchGetUserForGroup),
    fork(watchGetMicroCategories),
    fork(watchGetAllGroup),
    fork(watchSetMicroCategories),
    fork(watchGetSupergroupCategories),
    fork(watchSetSupergroupCategories),
    fork(watchGetSupergroupSections),
    fork(watchSetSupergroupSections),
    fork(watchScrapperDataFetch),
    fork(watchScrapperDataUpdate),
    fork(watchScrapperDataCreateCompany),
    fork(watchScrapperDataCompanyFetch),
    fork(watchScrapperDataCompanyUpdate),
    fork(watchScrapperDataCompanyCreateCompany),
    fork(watchScrapperDataCompanyDeleteCompany),
    fork(watchScrapperDataCampaignFetch),
    fork(watchScrapperDataCampaignUpdate),
    fork(watchScrapperDataCampaignCreateCompany),
    fork(watchScrapperDataCampaignDeleteCompany),
    fork (watchScrapperDataCampaignHostsFetch) ,
    fork (watchScrapperDataCampaignHostsUpdate) ,
    fork (watchScrapperDataCampaignHostsCreateCompany),
    fork (watchScrapperDataCampaignHostsDeleteCompany),
    fork (watchScrapperDataFairDeleteCompany),
    fork (watchGetMusic),
    fork (watchCreateClip),
    fork (watchGetSingleClip),
    fork (watchGetSupergroupTemplatesections),
    fork (watchSetSupergroupTemplatesections),
    fork (watchGetWhatsappData),
    fork (watchSetWhatsappData),
    fork (watchGetWhatsappProfiles),
    fork (watchSetWhatsappProfiles),
    fork (watchDelWhatsappProfiles),
    fork (watchGetTrainingcontent),
    fork (watchUpdateTrainingcontent),
    fork (watchDeleteTrainingcontent),
    fork (watchPostTrainingcontent),
    fork (watchGetMicroAreaList),
    fork (watchSetMicroArea),
    fork(watchDeleteTraininggroup),
    fork(watchUpdateTraininggroup),
    fork(watchPostTraininggroup),
    fork(watchGetTraininggroup),
    fork(watchDeleteTrainingsession),
    fork(watchUpdateTrainingsession),
    fork(watchPostTrainingsession),
    fork(watchGetTrainingsession),
    fork(watchDeleteCertificate),
    fork(watchUpdateCertificate),
    fork(watchPostCertificate),
    fork(watchGetCertificate),
    fork(watchGetOptions),
    fork(watchPostOptions),
    fork(watchDelMicroCertificates),
    fork(watchGetMicroCertificates),
    fork(watchLinkedInCode),
    fork(watchLinkedInAccessTokenValidity),
    fork(watchLinkedInGetConnectedOrganizations),
    fork(watchGetAudits)

 ];
}
