import { fork, actionChannel, put, take, call, race } from 'redux-saga/effects';
//import { delay } from 'redux-saga';
import * as actionTypes from './actionSuperGroups';
const settings = require('../../settings');

/**
 * Saga watchers
 */
export function *watchGetSuperGroups() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_SUPERGROUPS_REQUEST);
    while (payload = yield take(reqChannel)) {
        yield fork(getSuperGroups, payload);
    }
}

/**
 * Get clips 
 * @param {*} authToken 
 * @param {*} groupId 
 * @param {*} page 
 */
const httpGetSuperGroups = (authToken, userId) => { 

    return fetch(settings.api_base_path + settings.api_admin_path + '/user/' + userId +  '/supergroup', 
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + authToken
        }
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *getSuperGroups(action) {
    try {

        let result = yield call(httpGetSuperGroups, action.payload.authToken, action.payload.userId);

        var tmp = '';

        if (result.status == 'ok') {
            yield put({ type: actionTypes.GET_SUPERGROUPS_SUCCESS, payload: { superGroups: result.superGroups } });
        } else {
            // authorization error
    
        }
    } catch(error) {
        yield put({ type: actionTypes.SUPERGROUPS_ERROR, payload: error, error: true });
    }
}