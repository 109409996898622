import * as actionTypes from './actionGroups';

const initialState = {
    isLoading: false,
    error: null,
    updateDate: null,
    groups:[] 
};

const GroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_GROUPS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                groups: action.payload.groups
            });
        case actionTypes.GROUPS_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

export default GroupReducer;