import * as actionTypes from './action';

const initialState = {
    isLoading: false,
    error: undefined,
    auth: {},
    sidebarShow: true,

};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLEHEADERBAR :
          console.log('sidebarshow is: ', state.sidebarShow)
        return { ...state, sidebarShow: !state.sidebarShow }

        case actionTypes.AUTH_SIGNIN_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });
        case actionTypes.AUTH_SIGNUP_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });
        case actionTypes.AUTH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                auth: action.payload
            });
        case actionTypes.AUTH_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                auth: action.payload,
                error: action.error
            });
        case actionTypes.AUTH_SIGNOUT:
            return Object.assign({}, state, {
                isLoading: false,
                auth: {}
            });
        default:
            return state;
    }
}

export default AuthReducer;
