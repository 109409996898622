
export const CAMPAIGN_HOSTS_GET_SERVER_DATA = 'CAMPAIGN_HOSTS_GET_SERVER_DATA';
export const CAMPAIGN_HOSTS_GET_SERVER_DATA_SUCCESS = 'CAMPAIGN_HOSTS_GET_SERVER_DATA_SUCCESS';
export const CAMPAIGN_HOSTS_GET_SERVER_DATA_REQUEST = 'CAMPAIGN_HOSTS_GET_SERVER_DATA_REQUEST';


export const CAMPAIGN_HOSTS_UPDATE_LOCAL_STORE = 'CAMPAIGN_HOSTS_UPDATE_LOCAL_STORE';
export const CAMPAIGN_HOSTS_UPDATE_LOCAL_STORE_NEW = 'CAMPAIGN_HOSTS_UPDATE_LOCAL_STORE_NEW';

export const CAMPAIGN_HOSTS_UPDATE_SERVER_DATA = 'CAMPAIGN_HOSTS_UPDATE_SERVER_DATA';
export const CAMPAIGN_HOSTS_UPDATE_SERVER_DATA_SUCCESS = 'CAMPAIGN_HOSTS_UPDATE_SERVER_DATA_SUCCESS'
export const CAMPAIGN_HOSTS_UPDATE_SERVER_DATA_REQUEST = 'CAMPAIGN_HOSTS_UPDATE_SERVER_DATA_REQUEST'

export const CAMPAIGN_HOSTS_DELETE_SERVER_DATA = 'CAMPAIGN_HOSTS_DELETE_SERVER_DATA';
export const CAMPAIGN_HOSTS_DELETE_SERVER_DATA_SUCCESS = 'CAMPAIGN_HOSTS_DELETE_SERVER_DATA_SUCCESS'
export const CAMPAIGN_HOSTS_DELETE_SERVER_DATA_REQUEST = 'CAMPAIGN_HOSTS_DELETE_SERVER_DATA_REQUEST'

export const CAMPAIGN_HOSTS_CREATE_SERVER_DATA = 'CAMPAIGN_HOSTS_CREATE_SERVER_DATA';
export const CAMPAIGN_HOSTS_CREATE_SERVER_DATA_SUCCESS = 'CAMPAIGN_HOSTS_CREATE_SERVER_DATA_SUCCESS'
export const CAMPAIGN_HOSTS_CREATE_SERVER_DATA_REQUEST = 'CAMPAIGN_HOSTS_CREATE_SERVER_DATA_REQUEST'



export const CAMPAIGN_HOSTS_AUTH_ERROR = 'CAMPAIGN_HOSTS_AUTH_ERROR'


export const getServerData = (payload) => ({
    type: CAMPAIGN_HOSTS_GET_SERVER_DATA,
    payload
})

export const getServerDataRequest = (payload) => ({
    type: CAMPAIGN_HOSTS_GET_SERVER_DATA_REQUEST,
    payload
})

export const getServerDataSuccess = (payload) => ({
    type: CAMPAIGN_HOSTS_GET_SERVER_DATA_SUCCESS,
    payload
})


export const updateServerData = (payload) => ({
  type: CAMPAIGN_HOSTS_UPDATE_SERVER_DATA,
  payload
})

export const updateServerDataRequest = (payload) => ({
  type: CAMPAIGN_HOSTS_UPDATE_SERVER_DATA_REQUEST,
  payload
})

export const updateServerDataSuccess = (payload) => ({
  type: CAMPAIGN_HOSTS_UPDATE_SERVER_DATA_SUCCESS,
  payload
})

export const createServerData = (payload) => ({
  type: CAMPAIGN_HOSTS_CREATE_SERVER_DATA,
  payload
})

export const createServerDataRequest = (payload) => ({
  type: CAMPAIGN_HOSTS_CREATE_SERVER_DATA_REQUEST,
  payload
})

export const createServerDataSuccess = (payload) => ({
  type: CAMPAIGN_HOSTS_CREATE_SERVER_DATA_SUCCESS,
  payload
})


export const deleteServerData = (payload) => ({
  type: CAMPAIGN_HOSTS_DELETE_SERVER_DATA,
  payload
})

export const deleteServerDataRequest = (payload) => ({
  type: CAMPAIGN_HOSTS_DELETE_SERVER_DATA_REQUEST,
  payload
})

export const deleteServerDataSuccess = (payload) => ({
  type: CAMPAIGN_HOSTS_DELETE_SERVER_DATA_SUCCESS,
  payload
})

export const updateLocalStore = (payload) => ({
  type: CAMPAIGN_HOSTS_UPDATE_LOCAL_STORE,
  payload
})

export const updateLocalStoreNewHost = (payload) => ({
  type: CAMPAIGN_HOSTS_UPDATE_LOCAL_STORE_NEW,
  payload
})


export const authError = (error) => ({
    type: CAMPAIGN_HOSTS_AUTH_ERROR,
    error
})


