import { fork, actionChannel, takeEvery, put, take, call } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import * as loginActions from './action'
import * as categoryActions from '../../views/Clips/actionCategories'
import * as groupActions from '../../views/Clips/actionGroups'
import * as superGroupActions from '../../views/Clips/actionSuperGroups'
import * as adminGroupActions from '../../views/Administration/Gruppenverwaltung/action'
//import * as portalSections from '../../views/Administration/Portalsectionsmanagement/action'
//import * as templateaction from '../../views/Administration/Templatesectionsmanagement/action'
import * as musicActions from '../../views/Music/action'


const settings = require('../../settings')
/**
 * Saga watchers
 */
export function* watchSignin() {
  let payload
  console.log('in watchsignin now')
  const reqChannel = yield actionChannel(loginActions.AUTH_SIGNIN_REQUEST)
  while ((payload = yield take(reqChannel))) {
      yield fork(signin, payload);
  }
  //yield takeEvery(loginActions.AUTH_SIGNIN_REQUEST, signin)
}

export function *watchPWDReset() {
    let payload;
    const reqChannel = yield actionChannel(loginActions.PWD_RESET_REQUEST)
    while (payload = yield take(reqChannel)) {
        yield fork(pwdreset, payload);
    }
}

/**
 * Auth Signin endpoint
 * @param {*} payload
 */
const httpSignin = (payload) => {

    let header = new Headers({
        'Access-Control-Allow-Origin':'*',
        mode: 'cors',
        'Content-Type': 'application/json'
});


    return fetch( settings.api_base_path + '/noauth/signin',
    {
        method: 'POST',
        headers: header,
        body: JSON.stringify(payload)
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *signin(action) {

  console.log('before httpsSignin')


    try {
        const result = yield call(httpSignin, action.payload)
        yield put({ type: groupActions.GET_GROUPS_REQUEST, payload: { authToken: result.auth.sessionId, userId: result.auth.userId } });
        yield put({ type: superGroupActions.GET_SUPERGROUPS_REQUEST, payload: { authToken: result.auth.sessionId, userId: result.auth.userId } });
        yield put({ type: categoryActions.GET_CATEGORIES_REQUEST, payload: { authToken: result.auth.sessionId, groupId: result.auth.groupId } });
        yield put({ type: adminGroupActions.ALLGROUP_GET_REQUEST, payload: { authToken: result.auth.sessionId} });
        yield put({ type: adminGroupActions.USER_GET_REQUEST, payload: { authToken: result.auth.sessionId} });
        yield put({ type: musicActions.GET_MUSIC_REQUEST, payload: {}});

     //   yield put({ type: portalSections.GET_SECTIONSFORSUPERGROUP_REQUEST, payload: { authToken: result.auth.sessionId, sgid: result.auth.groupId} });

        console.log('status in saga is: ', result)

        if (result.status == 'ok') {
            yield put({ type: loginActions.AUTH_SUCCESS, payload: result.auth });

        } else {
            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },
                type: 'error',
                showConfirmButton: false,
                timer: 1500
                //confirmButtonText: 'Cool'
              })

            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }

    } catch(error) {
        Swal.fire({
            title: 'Error!',
            position: 'center',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })
        yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
    }
}


const pwdreset_fetch = (payload) => {

    const queryparams = {
        'email': payload.payload.email
    }
    return fetch( '/api/1.0/resetpassword/de',
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryparams)
    })
    .then((response) => response.json())
    .then((response) => {
        console.log(' pwd reset is: ', response);
        response.status == "error" ?
        Swal.fire({
            title: 'Error!',
            position: 'top-end',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Fehler bei Passwort-Zurücksetzen-Anfrage ',
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          }) :
          Swal.fire({
            title: 'Erfolgreich!',
            position: 'top-end',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Überprüfen Sie Ihr Email-Postfach!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })

    });
};

function *pwdreset(action) {
    try {
        const result = yield call(pwdreset_fetch, action.payload)


        if (result.status == 'ok') {
            yield put({ type: loginActions.PWD_SUCCESS, payload: result });

        } else {
            yield put({ type: loginActions.PWD_ERROR, payload: {}, error: true });
        }

    } catch(error) {
        yield put({ type: loginActions.PWD_ERROR, payload: error, error: true  });
    }
}
