
export const SET_SUPERGROUP = 'SET_SUPERGROUP';
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const UPDATE_SECTIONS = "UPDATE_SECTIONS";
export const UPDATE_COMPANIES = "UPDATE_COMPANIES"
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";

export const setSuperGroup = (payload) => ({
    type: SET_SUPERGROUP,
    payload
});

export const updateCategories = (payload) => ({
  type: UPDATE_CATEGORIES,
  payload
});

export const updateCompanies = (payload) => ({
  type: UPDATE_COMPANIES,
  payload
});

export const updateSections = (payload) => ({
  type: UPDATE_SECTIONS,
  payload
});
export const updateConfiguration = (payload) => ({
  type: UPDATE_CONFIGURATION,
  payload
});