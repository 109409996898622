import * as actionTypes from './action';

const initialState = {
    error: undefined,
    companies: [],
    auth: {}
};

const DataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SERVER_DATA:
            return Object.assign({}, state, {
                auth: action.payload.auth
            });
            case actionTypes.GET_SERVER_DATA_SUCCESS:
                return Object.assign({}, state, {
                    companies: action.payload
                });

            case actionTypes.UPDATE_LOCAL_STORE:
            let findcomp
            if (action.payload.id){
                if (state.companies){
                    findcomp = state.companies.find( cdata => cdata.id === action.payload.id)
                    if (findcomp){
                        console.log('value is in local store: ', action.payload.value)
                        findcomp[action.payload.key] = action.payload.value

                    }
                }
            }
            console.log('UPDATE_LOCAL_STORE: ', findcomp)
            return Object.assign({}, state, {
                companies: state.companies
            });

            case actionTypes.UPDATE_COMPANY_SERVER_DATA_SUCCESS: 

              var comps = Object.assign([], state.companies)
              var index = comps.findIndex( f => f.id === action.payload.id)
              console.log('Index is: ', index)


              comps = comps.filter(f => f.id != action.payload.id)

              comps.splice(index,0,action.payload)

             // comps.splice(index,0,action.payload)
              //comps.splice(comps.findIndex( f => f.id === action.payload.id),1,action.payload)


              return Object.assign({}, state, {
                companies: comps
              })
              case actionTypes.FAIR_DELETE_COMPANY_SERVER_DATA:
                console.debug('Scrapper-Companies-Reducer in delete Company : ', action.payload.id)
                let newobject = state.companies.filter( f => f.id !== action.payload.id )
              return Object.assign({}, state, {
                companies: newobject
              });
        default:
            return state;
    }
}

export default DataReducer;
