import * as actionTypes from './action';

const initialState = {
    superGroup: null,
    updateDate: Date.now()
};

const SystemStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SUPERGROUP: {
            return {
                ...state,
                superGroup: action.payload.superGroup,
                updateDate: Date.now()
            };
        }
        case actionTypes.UPDATE_CATEGORIES: {
         // state.superGroup.categories = action.payload.categories
          console.log('reducer dashboard: ', action.payload)
          return {
              ...state,
              superGroup: {
                ...state.superGroup,
                categories: action.payload.categories
              },
              updateDate: Date.now()
          };
          
      }
      case actionTypes.UPDATE_COMPANIES: {
        // state.superGroup.categories = action.payload.categories
         console.log('reducer dashboard companies: ', action.payload)
         return {
             ...state,
             superGroup: {
               ...state.superGroup,
               companies: action.payload.companies
             },
             updateDate: Date.now()
         };
         
     }      
      case actionTypes.UPDATE_CONFIGURATION: {
        // state.superGroup.categories = action.payload.categories
         console.log('reducer dashboard: ', action.payload)
         return {
             ...state,
             superGroup: {
               ...state.superGroup,
               configuration: action.payload.configuration
             },
             updateDate: Date.now()
         };
         
     }      

      case actionTypes.UPDATE_SECTIONS: {
        // state.superGroup.categories = action.payload.categories
         console.log('reducer dashboard: ', action.payload)
         return {
             ...state,
             superGroup: {
               ...state.superGroup,
               sections: action.payload.sections
             },
             updateDate: Date.now()
         };
         
     }      

        default:
            return state;
    }
};

export default SystemStateReducer;
