
export const GET_SECTIONSFORSUPERGROUP_ERROR = 'GET__SECTIONSFORSUPERGROUP_ERROR'
export const GET_SECTIONSFORSUPERGROUP_SUCCESS = 'GET__SECTIONSFORSUPERGROUP_SUCCESS'
export const GET_SECTIONSFORSUPERGROUP_REQUEST = 'GET__SECTIONSFORSUPERGROUP_REQUEST'
export const ADD_SECTIONSFORSUPERGROUP_TOLOCALSTORE = 'ADD__SECTIONSFORSUPERGROUP_TOLOCALSTORE'
export const DEL_SECTIONSFORSUPERGROUP_FROMLOCALSTORE = 'DEL__SECTIONSFORSUPERGROUP_FROMLOCALSTORE'


export const SET_SECTIONSFORSUPERGROUP_ERROR = 'SET_SECTIONSFORSUPERGROUP_ERROR'
export const SET_SECTIONSFORSUPERGROUP_SUCCESS = 'SET_SECTIONSFORSUPERGROUP_SUCCESS'
export const SET_SECTIONSFORSUPERGROUP_REQUEST = 'SET_SECTIONSFORSUPERGROUP_REQUEST'



export const getSectionsFORSUPERGROUPRequest = (payload) => ({
    type: GET_SECTIONSFORSUPERGROUP_REQUEST, 
    payload
})


export const getSectionsFORSUPERGROUPSuccess = (payload) => ({
    type: GET_SECTIONSFORSUPERGROUP_SUCCESS,
    payload
})

export const getSectionsFORSUPERGROUPError = (error) => ({
    type: GET_SECTIONSFORSUPERGROUP_ERROR,
    error
})


export const setSectionsFORSUPERGROUPRequest = (payload) => ({
    type: SET_SECTIONSFORSUPERGROUP_REQUEST, 
    payload
})


export const setSectionsFORSUPERGROUPSuccess = (payload) => ({
    type: SET_SECTIONSFORSUPERGROUP_SUCCESS,
    payload
})

export const setSectionsFORSUPERGROUPError = (error) => ({
    type: SET_SECTIONSFORSUPERGROUP_ERROR,
    error
})


export const delSectionsFORSUPERGROUPFromLocalStore = (payload) => ({
    type: DEL_SECTIONSFORSUPERGROUP_FROMLOCALSTORE,
    payload
})

export const addSectionsFORSUPERGROUPToLocalStore = (payload) => ({
    type: ADD_SECTIONSFORSUPERGROUP_TOLOCALSTORE,
    payload
})