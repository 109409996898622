import { red, blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#223344',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A700,
    },
    fab: {
        main: red.A700, 
    }
  },
  white: {
      main: '#ffffff', 
  }
});

export default theme;