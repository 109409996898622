export const POST_TRAININGCONTENT_ERROR = 'POST_TRAININGCONTENT_ERROR'
export const POST_TRAININGCONTENT_SUCCESS = 'POST_TRAININGCONTENT_SUCCESS'
export const POST_TRAININGCONTENT_REQUEST = 'POST_TRAININGCONTENT_REQUEST'

export const UPDATE_TRAININGCONTENT_ERROR = 'UPDATE_TRAININGCONTENT_ERROR'
export const UPDATE_TRAININGCONTENT_SUCCESS = 'UPDATE_TRAININGCONTENT_SUCCESS'
export const UPDATE_TRAININGCONTENT_REQUEST = 'UPDATE_TRAININGCONTENT_REQUEST'

export const DELETE_TRAININGCONTENT_ERROR = 'DELETE_TRAININGCONTENT_ERROR'
export const DELETE_TRAININGCONTENT_SUCCESS = 'DELETE_TRAININGCONTENT_SUCCESS'
export const DELETE_TRAININGCONTENT_REQUEST = 'DELETE_TRAININGCONTENT_REQUEST'

export const GET_TRAININGCONTENT_ERROR = 'GET_TRAININGCONTENT_ERROR'
export const GET_TRAININGCONTENT_SUCCESS = 'GET_TRAININGCONTENT_SUCCESS'
export const GET_TRAININGCONTENT_REQUEST = 'GET_TRAININGCONTENT_REQUEST'

export const CHANGE_LOCALSTORE_TRAININGCONTENT = 'CHANGE_LOCALSTORE_TRAININGCONTENT'


export const getTrainingcontentRequest = (payload) => ({
    type: GET_TRAININGCONTENT_REQUEST, 
    payload
})

export const getTrainingcontentSuccess = (payload) => ({
    type: GET_TRAININGCONTENT_SUCCESS, 
    payload
})

export const getTrainingcontentError = (payload) => ({
    type: GET_TRAININGCONTENT_ERROR, 
    payload
})

export const deleteTrainingcontentRequest = (payload) => ({
    type: DELETE_TRAININGCONTENT_REQUEST, 
    payload
})

export const deleteTrainingcontentSuccess = (payload) => ({
    type: DELETE_TRAININGCONTENT_SUCCESS, 
    payload
})

export const deleteTrainingcontentError = (payload) => ({
    type: DELETE_TRAININGCONTENT_ERROR, 
    payload
})

export const updateTrainingcontentRequest = (payload) => ({
    type: UPDATE_TRAININGCONTENT_REQUEST, 
    payload
})

export const updateTrainingcontentSuccess = (payload) => ({
    type: UPDATE_TRAININGCONTENT_SUCCESS, 
    payload
})

export const updateTrainingcontentError = (payload) => ({
    type: UPDATE_TRAININGCONTENT_ERROR, 
    payload
})

export const postTrainingcontentRequest = (payload) => ({
    type: POST_TRAININGCONTENT_REQUEST, 
    payload
})

export const postTrainingcontentSuccess = (payload) => ({
    type: POST_TRAININGCONTENT_SUCCESS, 
    payload
})

export const postTrainingcontentError = (payload) => ({
    type: POST_TRAININGCONTENT_ERROR, 
    payload
})

export const changeTrainingContentValuesLocalStore = (payload) => ({
    type: CHANGE_LOCALSTORE_TRAININGCONTENT, 
    payload
})