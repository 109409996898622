import * as actionTypes from './action';
import '../../i18n'
import { useTranslation } from 'react-i18next'


const initialState = {
    isLoading: false,
    error: null,
    status: null,
    clips:[],
    clipsshotsbasket: [],
    languages: null,
    updateDate: Date.now(),
    processLevel: "",
    page: 1,
};

const ClipReducer = ( state = initialState, action) => {

    switch (action.type) {

        case actionTypes.GET_CLIPS_SUCCESS: {
            //const clips = (action.payload.page == 1) ? action.payload.clips : [...state.clips, ...action.payload.clips];
            const clips = action.payload.processLevel === "finished" ? action.payload.clips.filter( clip => clip.processLevel=== "finished") : action.payload.clips

            return Object.assign({}, state, {
                ...state,
                isLoading: false,
                status: action.payload.status,
                clips: [...clips],
                updateDate: Date.now()
            });
        }

        case actionTypes.SET_CLIPS_SHOTS_BASKET: {

          console.log('in reducer - shots to be set are: ', action.payload)
          return Object.assign({}, state, {
              ...state,
              isLoading: false,
              status: "set shots of clips",
              clipsshotsbasket: action.payload,
              updateDate: Date.now()
          });
      }

        case actionTypes.UPDATECLIP_LOCALSTORE: {
            const clip = state.clips.find(cl => cl.uuid == action.payload.clip.uuid);
            console.log('update localstore is: ', action.payload)

            Object.assign(clip, action.payload.clip);
            return Object.assign({}, state, {
                ...state,
                isLoading: false,
                clips: [...state.clips],
                status: action.payload.status,
                updateDate: Date.now()
            });
        }

        case actionTypes.CLIPS_CREATE: {
      //      const clip = state.clips.find(cl => cl.uuid == action.payload.clip.uuid);

  //    console.log('payload in clips reducer: ', action.payload)
            const clip = action.payload.clip

           state.clips.unshift(clip)
           // const localclips = Object.assign(clip, ...state.clips);
           console.log('new clips: ', state.clips)
            return Object.assign({}, state, {
                ...state,
                isLoading: false,
                clips: [...state.clips],
                status: action.payload.status,
                updateDate: Date.now()
            });


        }

        case actionTypes.TRANSCRIPTION_REQUEST: {
            return Object.assign({}, state, {
                ...state,
                isLoading: true,
                updateDate: Date.now()
            });
        }

        case actionTypes.TRANSLATION_REQUEST: {
            return Object.assign({}, state, {
                ...state,
                isLoading: true,
                updateDate: Date.now()
            });
        }

        case actionTypes.TRANSLATION_REQUEST: {
            return Object.assign({}, state, {
                ...state,
                isLoading: true,
                updateDate: Date.now()
            });
        }

        case actionTypes.BURN_SUBTITLES_REQUEST: {
            return Object.assign({}, state, {
                ...state,
                isLoading: true,
                updateDate: Date.now()
            });
        }

        case actionTypes.CLIPS_ERROR: {
            return Object.assign({}, state, {
                ...state,
                isLoading: false,
                status: action.payload.status,
                error: action.payload.error,
                updateDate: Date.now()
            });
        }
        default:
            return state;
    }
};

export default ClipReducer;
