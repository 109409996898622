import { fork, actionChannel, put, take, call, race } from 'redux-saga/effects';
//import { delay } from 'redux-saga'
import * as actionTypes from './actionGroups'
const settings = require('../../settings');

/**
 * Saga watchers
 */
export function *watchGetGroups() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_GROUPS_REQUEST)
    while (payload = yield take(reqChannel)) {
        yield fork(getGroups, payload);
    }
}

/**
 * Get clips 
 * @param {*} authToken 
 * @param {*} groupId 
 * @param {*} page 
 */
const httpGetGroups = (authToken, userId) => { 
    return fetch(settings.api_base_path + settings.api_admin_path + '/user/' + userId +  '/group', 
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + authToken
        }
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *getGroups(action) {
    try {
        let result = yield call(httpGetGroups, action.payload.authToken, action.payload.userId);

        let tmp = '';

        if (result.status == 'ok') {
            yield put({ type: actionTypes.GET_GROUPS_SUCCESS, payload: { groups: result.groups } });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.GROUPS_ERROR, payload: error, error: true });
    }
}