/**
 * Clip action types
 */
export const GET_SUPERGROUPS_REQUEST = 'GET_SUPERGROUPS_REQUEST';
export const GET_SUPERGROUPS_SUCCESS = 'GET_SUPERGROUPS_SUCCESS';
export const SUPERGROUPS_ERROR = 'SUPERGROUPS_ERROR';
export const UPDATE_GROUPS_LOCALSTORE = 'UPDATE_GROUPS_LOCALSTORE';
export const DELETE_GROUP_LOCALSTORE = 'DELETE_GROUPS_LOCALSTORE';
export const UPDATE_SUPERGROUP_CONFIGURATION_LOCALSTORE = 'UPDATE_SUPERGROUP_CONFIGURATION'

export const CLIPS_ERROR = 'CLIPS_ERROR';

export const getSuperGroupsRequest = (payload) => ({
    type: GET_SUPERGROUPS_REQUEST,
    payload
});

export const deleteGroupLocalStore = (payload) => ({
    type: DELETE_GROUP_LOCALSTORE,
    payload
});

export const updateGroupLocalStore = (payload) => ({
    type: UPDATE_GROUPS_LOCALSTORE,
    payload
});

export const updateSupergroupConfiguration = (payload) => ({
    type: UPDATE_SUPERGROUP_CONFIGURATION_LOCALSTORE,
    payload
});

export const getSuperGroupsSuccess = (payload) => ({
    type: GET_SUPERGROUPS_SUCCESS,
    payload
});


export const superGroupsError = (error) => ({
    type: SUPERGROUPS_ERROR,
    error
});
