import { fork, actionChannel, put, take, call } from 'redux-saga/effects';
import * as adminActions from './action';
//import Swal from 'sweetalert2';

const settings = require('../../settings');


/**
 * Saga watchers
 */
export function* watchGetTrainingcontent() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_TRAININGCONTENT_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandler, payload);
    }
}

export function* watchPostTrainingcontent() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.POST_TRAININGCONTENT_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandler, payload);
    }
}

export function* watchUpdateTrainingcontent() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.UPDATE_TRAININGCONTENT_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandler, payload);
    }
}

export function* watchDeleteTrainingcontent() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.DELETE_TRAININGCONTENT_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(universalSagaHandler, payload);
    }
}

const universalSagaMethodExecuter = async (payload) => {

    //payload must contain: route, method, authToken
    //if put, delete additionally: contentid

    console.log('universalSagaMethodExecuter - json is: ', payload)

    let mainbody = {
        method: payload.method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        }
    }

    if (["POST", "PUT"].includes(payload.method)) mainbody.body = JSON.stringify(payload.body)


    try {
        if (payload.hasOwnProperty("enqueueSnackbar")) payload.enqueueSnackbar(payload.t('general.savesuccess'), { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })

        let f = await fetch(payload.route, mainbody)//.then((res) => res.json()).then((data) => data);

        let f1 = f.json()
        let f2 = await f1
        return f2
    } catch (error) {
        if (payload.hasOwnProperty("enqueueSnackbar")) payload.enqueueSnackbar(payload.t('general.error'), { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right', } })
        return null
    }




}




function* universalSagaHandler(action) {

    let [erroraction, successindicator] = ""

    if (["POST"].includes(action.payload.method)) {
        successindicator = adminActions.POST_TRAININGCONTENT_SUCCESS
        erroraction = adminActions.POST_TRAININGCONTENT_ERROR
    } else if (["PUT"].includes(action.payload.method)) {
        successindicator = adminActions.UPDATE_TRAININGCONTENT_SUCCESS
        erroraction = adminActions.UPDATE_TRAININGCONTENT_ERROR
    } else if (["DELETE"].includes(action.payload.method)) {
        successindicator = adminActions.DELETE_TRAININGCONTENT_SUCCESS
        erroraction = adminActions.DELETE_TRAININGCONTENT_ERROR
    } else if (["GET"].includes(action.payload.method)) {
        successindicator = adminActions.GET_TRAININGCONTENT_SUCCESS
        erroraction = adminActions.GET_TRAININGCONTENT_ERROR
    }

    try {
        const result = yield call(universalSagaMethodExecuter, action.payload)

        console.log('redux training univesalsaga is: ', result)

        if (result.status === "ok") {
            console.log('Payload is now: ', result)

            yield put({ type: successindicator, payload: { isLoading: true, trainingcontent: result.trainingcontent } });
        } else {
            yield put({ type: erroraction, payload: { error: "Error in Saga function universalSagaHandler [Training->saga.js] " + erroraction } });
        }


    } catch (error) {
        // console.log('Error feedback in redux-store is. ', error)

        yield put({ type: erroraction, payload: { error } });
    }
}

