import {fork, actionChannel, put, take, call} from 'redux-saga/effects';
import * as adminActions from './action';
const settings = require('../../../settings');

/**
 * Saga watchers
 */
export function *watchGetUser() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.USER_GET_REQUEST)
    while (payload = yield take(reqChannel)) {
      
        yield fork(getUserList, payload);
    }
}


export function *watchAddUserToGroup() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.ADD_USER_REQUEST)
    while (payload = yield take(reqChannel)) {
      
        yield fork(addUserToGroup, payload);
    }
}

export function *watchGetUserForGroup() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_USERFORGROUP_REQUEST)
    while (payload = yield take(reqChannel)) {
      
        yield fork(getUserForGroup, payload);
    }
}

export function *watchGetAllGroup() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.ALLGROUP_GET_REQUEST)
    while (payload = yield take(reqChannel)) {
      
        yield fork(getAllGroupList, payload);
    }
}




const getUserFromjoobster = (payload) => { // Alle für diese Gruppe zulaessigen User    
    console.log("GETUSERFROMJOOBSTER IN REDUX: ", payload)
  return fetch( settings.api_base_path + settings.api_admin_path + "/user" ,  {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer ' + payload.authToken
      },      
    }).then((res) => res.json())
    .then((data) => data);

  }


  const getAllGroupFromjoobster = (payload) => { // Alle für diese Gruppe zulaessigen User    
    console.log("GETUSERFROMJOOBSTER IN REDUX: ", payload)
  return fetch( settings.api_base_path + settings.api_admin_path + "/group" ,  {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer ' + payload.authToken
      },      
    }).then((res) => res.json())
    .then((data) => data);

  }


  const getUserForGroupjoobster = (payload) => { // Alle für diese Gruppe zulaessigen User    
    console.log("GETUSERFORGRUOPJOOBSTER IN REDUX: ", payload)
    
       return  fetch( settings.api_base_path + settings.api_admin_path + "/group/" + payload.id + "/user" ,  {
          method: 'GET',
          headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + payload.authToken
          },
          
        }).then((res) => res.json())
        .then((data) => data);
        
  }



  const addUserFromGroupjoobster =(payload) => {


    fetch( settings.api_base_path + settings.api_admin_path + "/group/" + payload.gid + '/user/' + payload.uid ,  {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + payload.authToken
        },
        //body:JSON.stringify(grouppost)
      }).then((res) => res.json())
      .then((data) => { 
        console.log('PUT wurde abgesetzt für userAdd: ', data);
        //fetchresponse = data;
      })
      .catch((err)=>{
        console.log(err);
      });	
    
  }


  function *addUserToGroup(action) {
    try {
        const result = yield call(addUserFromGroupjoobster, action.payload)

        console.log('SAGA RESULT ON ADD user to Group:', result); 
        if (result.status == 'ok') {
            yield put({ type: adminActions.ADD_USER_SUCCESS, payload: result });
        } else {
            yield put({ type: adminActions.ADD_USER_ERROR, payload: {}, error: true });
        }
        
    } catch(error) {
        yield put({ type: adminActions.ADD_USER_ERROR, payload: error, error: true  });
    }
}


function *getAllGroupList(action) {
    try {
        const result = yield call(getAllGroupFromjoobster, action.payload)

        console.log('SAGA RESULT:', result); 
        if (result.status == 'ok') {
            yield put({ type: adminActions.ALLGROUP_SUCCESS, payload: result.groups });
        } else {
            yield put({ type: adminActions.ALLGROUP_ERROR, payload: {}, error: true });
        }
        
    } catch(error) {
        yield put({ type: adminActions.ALLGROUP_ERROR, payload: error, error: true  });
    }
}


function *getUserList(action) {
    try {
        const result = yield call(getUserFromjoobster, action.payload)

        console.log('SAGA RESULT:', result); 
        if (result.status == 'ok') {
            yield put({ type: adminActions.USER_SUCCESS, payload: result.users });
        } else {
            yield put({ type: adminActions.USER_ERROR, payload: {}, error: true });
        }
        
    } catch(error) {
        yield put({ type: adminActions.USER_ERROR, payload: error, error: true  });
    }
}

function *getUserForGroup(action) {
    try {
        const result = yield call(getUserForGroupjoobster, action.payload)

        console.log('SAGA RESULT ON GETUSERFORGROUP:', result); 
        if (result.status == 'ok') {
            yield put({ type: adminActions.GET_USERFORGROUP_SUCCESS, payload: result.users });
        } else {
            yield put({ type: adminActions.GET_USERFORGROUP_ERROR, payload: {}, error: true });
        }
        
    } catch(error) {
        yield put({ type: adminActions.GET_USERFORGROUP_ERROR, payload: error, error: true  });
    }
}

