import * as actionTypes from './action';

const initialState = {
    error: undefined,
    linkedIn: {},
    facebook: {},
    youtube: {},
    tiktok: {}, 
    /*
    is an object {
        creatorinfo: {}
        access_token...
    }
    */
    status: ""
};

const SocialShareReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LINKEDIN_LOGIN_CODE:

            let loc_linkedIn = {code: action.code, accesstoken: ""}
            return Object.assign({}, state, {
                linkedIn: loc_linkedIn,
                status: "LINKEDIN CODE SET",
                error: "NONE"
            });

            case actionTypes.SET_FACEBOOK_LOGINDATA:
                console.log("now in setfblogindata, with data: ", action.payload)

            return Object.assign({}, state, {
                facebook:  action.payload,
                status: "FACEBOOK LOGINDATA SET",
                error: "NONE"
            });

            case actionTypes.SET_YOUTUBE_LOGINDATA:
                console.log("now in setytlogindata, with data: ", action.payload)

            return Object.assign({}, state, {
                youtube:  action.payload,
                status: "YOUTUBE LOGINDATA SET",
                error: "NONE"
            });
            case actionTypes.SET_TIKTOK_LOGINDATA:
                console.log("now in settiktoklogindate, with data: ", action.payload)

            return Object.assign({}, state, {
                tiktok:  action.payload,
                status: "TIKTOK LOGINDATA SET",
                error: "NONE"
            });            

            case actionTypes.GET_LINKEDIN_LOGIN_CODE:

            let loc_gllc = { code: action.payload.code, accesstoken: action.payload.token}

            return Object.assign({}, state, {
                linkedIn: loc_gllc,
                status: "GOT LINKEDIN CODE",
                error: "NONE"
            });

            case actionTypes.GET_LINKEDIN_LOGIN_TOKEN:

                let loc_gllt = { code: action.payload.code}
                return Object.assign({}, state, {
                    linkedIn: loc_gllt,
                    status: "GOT LINKEDIN ACCESS TOKEN",
                    error: "NONE"
                });

                case actionTypes.GET_LINKEDIN_LOGIN_TOKEN_REQUEST:
                  let loc_glltr = state.linkedIn
                  loc_glltr.accesstoken = action.payload.token
                  return Object.assign({}, state, {
                      linkedIn: loc_glltr,
                      status: "GOT LINKEDIN ACCESS TOKEN",
                      error: "NONE"
                  });

                  case actionTypes.GET_LINKEDIN_ORGANIZATIONS_REQUEST:
                    let loc_glor = state.linkedIn
                    loc_glor.organizations= action.payload.organizations
                    return Object.assign({}, state, {
                        linkedIn: loc_glor,
                        status: "GOT LINKEDIN CONNECTED ORGANIZATIONS",
                        error: "NONE"
                    });

            case actionTypes.GET_LINKEDIN_LOGIN_TOKEN_SUCCESS:
                return Object.assign({}, state, {
                    status: "GOT LINKEDIN ACCESS TOKEN",
                    error: "NONE"
                });

                case actionTypes.RESET_LOCAL_STORE_SOCIALSHARE:

                  console.log('action.payload: ', action.payload)

                  if (action.payload.hasOwnProperty("socialnetwork"))
                  return Object.assign({}, state, {
                    status: "Social store reset",
                    error: "",
                    [action.payload.socialnetwork]: {}
                  })


                  return Object.assign({}, state, {
                    status: "",
                    error: "",
                    linkedIn: {},
                    facebook: {},
                    youtube: {}, 
                    tiktok: {}
                  });


        default:
            return state;
    }
}

export default SocialShareReducer;
