
export const COMPANY_GET_SERVER_DATA = 'COMPANY_GET_SERVER_DATA';
export const COMPANY_GET_SERVER_DATA_SUCCESS = 'COMPANY_GET_SERVER_DATA_SUCCESS';
export const COMPANY_GET_SERVER_DATA_REQUEST = 'COMPANY_GET_SERVER_DATA_REQUEST';

export const COMPANY_UPDATE_LOCAL_STORE = 'COMPANY_UPDATE_LOCAL_STORE';
export const COMPANY_UPDATE_LOCAL_STORE_NEW_COMPANY = 'COMPANY_UPDATE_LOCAL_STORE_NEW_COMPANY';
export const COMPANY_DELETE_COMPANY_LOCAL_STORE = "COMPANY_DELETE_COMPANY_LOCAL_STORE"

export const COMPANY_UPDATE_COMPANY_SERVER_DATA = 'COMPANY_UPDATE_COMPANY_SERVER_DATA';
export const COMPANY_UPDATE_COMPANY_SERVER_DATA_SUCCESS = 'COMPANY_UPDATE_COMPANY_SERVER_DATA_SUCCESS'
export const COMPANY_UPDATE_COMPANY_SERVER_DATA_REQUEST = 'COMPANY_UPDATE_COMPANY_SERVER_DATA_REQUEST'

export const COMPANY_DELETE_COMPANY_SERVER_DATA = 'COMPANY_DELETE_COMPANY_SERVER_DATA';
export const COMPANY_DELETE_COMPANY_SERVER_DATA_SUCCESS = 'COMPANY_DELETE_COMPANY_SERVER_DATA_SUCCESS'
export const COMPANY_DELETE_COMPANY_SERVER_DATA_REQUEST = 'COMPANY_DELETE_COMPANY_SERVER_DATA_REQUEST'

export const COMPANY_CREATE_COMPANY_SERVER_DATA = 'COMPANY_CREATE_COMPANY_SERVER_DATA';
export const COMPANY_CREATE_COMPANY_SERVER_DATA_SUCCESS = 'COMPANY_CREATE_COMPANY_SERVER_DATA_SUCCESS'
export const COMPANY_CREATE_COMPANY_SERVER_DATA_REQUEST = 'COMPANY_CREATE_COMPANY_SERVER_DATA_REQUEST'


export const COMPANY_AUTH_ERROR = 'COMPANY_AUTH_ERROR'

export const getServerData = (payload) => ({
    type: COMPANY_GET_SERVER_DATA,
    payload
})

export const getServerDataRequest = (payload) => ({
    type: COMPANY_GET_SERVER_DATA_REQUEST,
    payload
})

export const getServerDataSuccess = (payload) => ({
    type: COMPANY_GET_SERVER_DATA_SUCCESS,
    payload
})

export const updateCompanyServerData = (payload) => ({
  type: COMPANY_UPDATE_COMPANY_SERVER_DATA,
  payload
})

export const updateCompanyServerDataRequest = (payload) => ({
  type: COMPANY_UPDATE_COMPANY_SERVER_DATA_REQUEST,
  payload
})

export const updateCompanyServerDataSuccess = (payload) => ({
  type: COMPANY_UPDATE_COMPANY_SERVER_DATA_SUCCESS,
  payload
})

export const createCompanyServerData = (payload) => ({
  type: COMPANY_CREATE_COMPANY_SERVER_DATA,
  payload
})

export const createCompanyServerDataRequest = (payload) => ({
  type: COMPANY_CREATE_COMPANY_SERVER_DATA_REQUEST,
  payload
})

export const createCompanyServerDataSuccess = (payload) => ({
  type: COMPANY_CREATE_COMPANY_SERVER_DATA_SUCCESS,
  payload
})


export const deleteCompanyServerData = (payload) => ({
  type: COMPANY_DELETE_COMPANY_SERVER_DATA,
  payload
})

export const deleteCompanyServerDataRequest = (payload) => ({
  type: COMPANY_DELETE_COMPANY_SERVER_DATA_REQUEST,
  payload
})

export const deleteCompanyServerDataSuccess = (payload) => ({
  type: COMPANY_DELETE_COMPANY_SERVER_DATA_SUCCESS,
  payload
})

export const deleteCompanyLocalStore = (payload) => ({
  type: COMPANY_DELETE_COMPANY_LOCAL_STORE,
  payload
})

export const updateLocalStore = (payload) => ({
  type: COMPANY_UPDATE_LOCAL_STORE,
  payload
})

export const updateLocalStoreNewCompany = (payload) => ({
  type: COMPANY_UPDATE_LOCAL_STORE_NEW_COMPANY,
  payload
})

export const authError = (error) => ({
    type: COMPANY_AUTH_ERROR,
    error
})

