import { fork, actionChannel, put, take, call, race } from 'redux-saga/effects';
import * as actionTypes from './action'


/**
 * Saga watchers
 */
export function *watchGetMusic() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_MUSIC_REQUEST)
    while (payload = yield take(reqChannel)) {
      console.log('......................................................................IN WATCHGETCLIPS.......................')
        yield fork(getMusic, payload);
    }
}

/**
 * Get music 
 * @param {*} authToken 
 * @param {*} groupId 
 * @param {*} page 
 */
const httpGetMusic = (payload) => { 
 
    return fetch('/api/1.0/public/sounds', 
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then((response) => response.json())
    .then((response) => response);

    

};


function *getMusic(action) {


    try {

        let result = yield call(httpGetMusic, action.payload);

        if (result.status == 'ok') {

            yield put({ type: actionTypes.GET_MUSIC_SUCCESS, payload: { sounds: result.sounds, status: result.status, error: false } });
        } else {
            // authorization error            
                        
            yield put({ type: actionTypes.MUSIC_ERROR, payload: {status: result.status, error: true }});

        }
    } catch(error) {
        console.log('::::::::::::::::::::::::::::::::::CLIPS GETTER mega error:::::::::::::::::::::::::::::::::::')

        yield put({ type: actionTypes.MUSIC_ERROR, payload:  {error: true }});


    }

}
