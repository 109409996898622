import { fork, actionChannel, put, take, call } from 'redux-saga/effects';
import * as adminActions from './action';
//import * as catActions from '../../Dashboard/action'
const settings = require('../../../settings');



/**
 * Saga watchers
 */
export function* watchGetSupergroupSections() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.GET_SECTIONSFORSUPERGROUP_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(getSectionsList, payload);
    }
}

export function* watchSetSupergroupSections() {
    let payload;
    const reqChannel = yield actionChannel(adminActions.SET_SECTIONSFORSUPERGROUP_REQUEST)
    while (payload = yield take(reqChannel)) {

        yield fork(setSectionsList, payload);
    }
}

const getSectionsFromjoobster = (payload) => {


    console.log('this is the category payload object: ', payload)
    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/section", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },

    }).then((res) => res.json())
        .then((data) => data);


}




function* getSectionsList(action) {
    try {
        const result = yield call(getSectionsFromjoobster, action.payload)

        if (result.status == "ok") {
            yield put({ type: adminActions.GET_SECTIONSFORSUPERGROUP_SUCCESS, payload: result.sections });
        } else {
            yield put({ type: adminActions.GET_SECTIONSFORSUPERGROUP_ERROR, payload: {}, error: true });
        }

    } catch (error) {
        yield put({ type: adminActions.GET_SECTIONSFORSUPERGROUP_ERROR, payload: error, error: true });
    }
}



const setSectionsTojoobster = (payload) => {

    return fetch(settings.api_base_path + settings.api_admin_path + "/supergroup/" + payload.sgid + "/section", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + payload.authToken
        },
        body: JSON.stringify(payload.body),
    }).then((res) => res.json() )
        .then((data) => data);


}




function* setSectionsList(action) {
    try {
        const result = yield call(setSectionsTojoobster, action.payload)

        if (result.status == "ok") {



            yield put({ type: adminActions.SET_SECTIONSFORSUPERGROUP_SUCCESS, payload: result.sections });
//            yield put({ type: catActions.UPDATE_CATEGORIES, payload: {categories: result.categories} });

        } else {
            yield put({ type: adminActions.SET_SECTIONSFORSUPERGROUP_ERROR, payload: {}, error: true });
        }

    } catch (error) {
        yield put({ type: adminActions.SET_SECTIONSFORSUPERGROUP_ERROR, payload: error, error: true });
    }
}


