import * as actionTypes from './action';

const initialState = {
    isLoading: false,
    error: undefined,
    users: {}, 
    userForGroup: {}, 
    allgroups: {}
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
    /*    case actionTypes.USER_GET_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });
            */

        case actionTypes.ADD_USER_SUCCESS: 
            
            return;

        case actionTypes.GET_USERFORGROUP_SUCCESS: 
            
            return Object.assign({}, state, {
                isLoading: false,
                userForGroup: [...action.payload]
            });
          
            
            case actionTypes.UPDATE_USERACTIVE:
            var user = state.users.find( f => f.id == action.payload.id);
            user.isActive = action.payload.active;

            return Object.assign({}, state, {
                isLoading: false,
                users: [...state.users]
            });

            case actionTypes.UPDATE_USERBACKENDACTIVE:
            var user = state.users.find( f => f.id == action.payload.id);
            user.isBackendActive = action.payload.active;

            return Object.assign({}, state, {
                isLoading: false,
                users: [...state.users]
            });

            case actionTypes.UPDATE_USERMODULEACTIVE:
            var user = state.users.find( f => f.id == action.payload.id);
            
            if ( action.payload.active && !user.routes.includes(action.payload.name.toUpperCase())) user.routes = [ ...user.routes, action.payload.name.toUpperCase()] // add route = module
            if ( !action.payload.active && user.routes.includes(action.payload.name.toUpperCase())) user.routes = user.routes.map(ur => ur!==action.payload.name.toUpperCase()) // remove route = module
                
            return Object.assign({}, state, {
                isLoading: false,
                users: [...state.users]
            });            
            
            case actionTypes.UPDATE_USERFORGROUP: 
            var localegroup;
            if (action.payload.operation == "add") {                
                return Object.assign({}, state, {
                    isLoading: false,
                    userForGroup: [...state.userForGroup, { id: action.payload.userid, email: action.payload.email} ]
                });                 
            }else{

                localegroup = state.userForGroup.filter( f => f.id != action.payload.userid);
                return Object.assign({}, state, {
                    isLoading: false,
                    userForGroup: localegroup
                });                 
            };

            case actionTypes.ALLGROUP_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                allgroups: [...action.payload]
            });            
            case actionTypes.ALLGROUP_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });


        case actionTypes.USER_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                users: [...action.payload]
            });
        case actionTypes.USER_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

        default:
            return state;
    }
}

export default userReducer;