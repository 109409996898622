import * as actionTypes from './action';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    error: undefined,
    sections: {},
};


const sectionReducerForSupergroup = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_SECTIONSFORSUPERGROUP_SUCCESS:

            return Object.assign({}, state, {
                isLoading: false,
                sections: [...action.payload]
                
            });
            case actionTypes.SET_SECTIONSFORSUPERGROUP_SUCCESS:

            Swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Kategorie erfolgreich gespeichert \n',
                showConfirmButton: false,
                timer: 1500
              }) 

            return Object.assign({}, state, {
                isLoading: false,
                sections: [...action.payload]
                
            });

        case actionTypes.DEL_SECTIONSFORSUPERGROUP_FROMLOCALSTORE: 

        var localcategories = new Array();

        if (action.payload.name)
         localcategories = state.sections.filter( f => f.name != action.payload.name);
        


        return Object.assign({}, state, {
          isLoading: false,
          sections:  [...localcategories]                   
      });

      case actionTypes.ADD_SECTIONSFORSUPERGROUP_TOLOCALSTORE: 

      state.sections.push(action.payload.section)

      return Object.assign({}, state, {
        isLoading: false,
        sections:  state.sections                   
    });      

        case actionTypes.GET_SECTIONSFORSUPERGROUP_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

            case actionTypes.SET_SECTIONSFORSUPERGROUP_ERROR:

            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Problem beim speichern der Kategorie!',
                type: 'error',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },       
                showConfirmButton: false, 
                timer: 1500
                //confirmButtonText: 'Cool'
              })  

            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

        default:
            return state;
    }
}

export default sectionReducerForSupergroup;