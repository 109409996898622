
export const GET_MICROCATEGORIES_ERROR = 'GET_MICROCATEGORIES_ERROR'
export const GET_MICROCATEGORIES_SUCCESS = 'GET_MICROCATEGORIES_SUCCESS'
export const GET_MICROCATEGORIES_REQUEST = 'GET_MICROCATEGORIES_REQUEST'
export const ADD_MICROCATEGORY_TOLOCALSTORE = 'ADD_MICROCATEGORY_TOLOCALSTORE'
export const DEL_MICROCATEGORY_FROMLOCALSTORE = 'DEL_MICROCATEGORY_FROMLOCALSTORE'


export const SET_MICROCATEGORIES_ERROR = 'SET_MICROCATEGORIES_ERROR' //ARE HIERARCHIES
export const SET_MICROCATEGORIES_SUCCESS = 'SET_MICROCATEGORIES_SUCCESS'
export const SET_MICROCATEGORIES_REQUEST = 'SET_MICROCATEGORIES_REQUEST'

export const SET_MICROAREA_REQUEST = 'SET_MICROAREA_REQUEST'
export const SET_MICROAREA_SUCCESS = 'SET_MICROAREA_SUCCESS'
export const SET_MICROAREA_ERROR = 'SET_MICROAREA_ERROR'

export const DEL_MICROAREA_ERROR = 'DEL_MICROAREA_ERROR'
export const DEL_MICROAREA_SUCCESS = 'DEL_MICROAREA_SUCCESS'
export const DEL_MICROAREA_REQUEST = 'DEL_MICROAREA_REQUEST'

export const GET_MICROAREA_ERROR = 'GET_MICROAREA_ERROR' //MICROAREA = TRAININGHIERARCHY
export const GET_MICROAREA_SUCCESS = 'GET_MICROAREA_SUCCESS'
export const GET_MICROAREA_REQUEST = 'GET_MICROAREA_REQUEST'

export const CLEAR_LOCAL_MICROCATEGORIES = 'CLEAR_LOCAL_MICROCATEGORIES'
export const SET_LOCAL_MICROAREA = 'SET_LOCAL_MICROAREA'


export const GET_TRAININGSESSION_SUCCESS = 'GET_TRAININGSESSION_SUCCESS'
export const GET_TRAININGSESSION_ERROR = 'GET_TRAININGSESSION_ERROR'
export const GET_TRAININGSESSION_REQUEST = 'GET_TRAININGSESSION_REQUEST'

export const PUT_TRAININGSESSION_SUCCESS = 'PUT_TRAININGSESSION_SUCCESS'
export const PUT_TRAININGSESSION_ERROR = 'PUT_TRAININGSESSION_ERROR'
export const PUT_TRAININGSESSION_REQUEST = 'PUT_TRAININGSESSION_REQUEST'

export const POST_TRAININGSESSION_SUCCESS = 'POST_TRAININGSESSION_SUCCESS'
export const POST_TRAININGSESSION_ERROR = 'POST_TRAININGSESSION_ERROR'
export const POST_TRAININGSESSION_REQUEST = 'POST_TRAININGSESSION_REQUEST'

export const DEL_TRAININGSESSION_SUCCESS = 'DEL_TRAININGSESSION_SUCCESS'
export const DEL_TRAININGSESSION_ERROR = 'DEL_TRAININGSESSION_ERROR'
export const DEL_TRAININGSESSION_REQUEST = 'DEL_TRAININGSESSION_REQUEST'


export const GET_TRAININGGROUP_SUCCESS = 'GET_TRAININGGROUP_SUCCESS'
export const GET_TRAININGGROUP_ERROR = 'GET_TRAININGGROUP_ERROR'
export const GET_TRAININGGROUP_REQUEST = 'GET_TRAININGGROUP_REQUEST'

export const PUT_TRAININGGROUP_SUCCESS = 'PUT_TRAININGGROUP_SUCCESS'
export const PUT_TRAININGGROUP_ERROR = 'PUT_TRAININGGROUP_ERROR'
export const PUT_TRAININGGROUP_REQUEST = 'PUT_TRAININGGROUP_REQUEST'

export const POST_TRAININGGROUP_SUCCESS = 'POST_TRAININGGROUP_SUCCESS'
export const POST_TRAININGGROUP_ERROR = 'POST_TRAININGGROUP_ERROR'
export const POST_TRAININGGROUP_REQUEST = 'POST_TRAININGGROUP_REQUEST'

export const DEL_TRAININGGROUP_SUCCESS = 'DEL_TRAININGGROUP_SUCCESS'
export const DEL_TRAININGGROUP_ERROR = 'DEL_TRAININGGROUP_ERROR'
export const DEL_TRAININGGROUP_REQUEST = 'DEL_TRAININGGROUP_REQUEST'


export const GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS'
export const GET_CERTIFICATE_ERROR = 'GET_CERTIFICATE_ERROR'
export const GET_CERTIFICATE_REQUEST = 'GET_CERTIFICATE_REQUEST'

export const PUT_CERTIFICATE_SUCCESS = 'PUT_CERTIFICATE_SUCCESS'
export const PUT_CERTIFICATE_ERROR = 'PUT_CERTIFICATE_ERROR'
export const PUT_CERTIFICATE_REQUEST = 'PUT_CERTIFICATE_REQUEST'

export const POST_CERTIFICATE_SUCCESS = 'POST_CERTIFICATE_SUCCESS'
export const POST_CERTIFICATE_ERROR = 'POST_CERTIFICATE_ERROR'
export const POST_CERTIFICATE_REQUEST = 'POST_CERTIFICATE_REQUEST'

export const DEL_CERTIFICATE_SUCCESS = 'DEL_CERTIFICATE_SUCCESS'
export const DEL_CERTIFICATE_ERROR = 'DEL_CERTIFICATE_ERROR'
export const DEL_CERTIFICATE_REQUEST = 'DEL_CERTIFICATE_REQUEST'

export const GET_MICROOPTIONS_SUCCESS = 'GET_MICROOPTIONS_SUCCESS'
export const GET_MICROOPTIONS_ERROR = 'GET_MICROOPTIONS_ERROR'
export const GET_MICROOPTIONS_REQUEST = 'GET_MICROOPTIONS_REQUEST'
export const POST_MICROOPTIONS_SUCCESS = 'POST_MICROOPTIONS_SUCCESS'
export const POST_MICROOPTIONS_ERROR = 'POST_MICROOPTIONS_ERROR'
export const POST_MICROOPTIONS_REQUEST = 'POST_MICROOPTIONS_REQUEST'
export const SET_MICROOPTIONS_LOCAL = 'SET_MICROOPTIONS_LOCAL'
export const SET_LOCAL_CATEGORYTREE = 'SET_LOCAL_CATEGORYTREE'

export const GET_MICROCERTIFICATES_SUCCESS = "GET_MICROCERTIFICATES_SUCCESS"
export const GET_MICROCERTIFICATES_REQUEST = "GET_MICROCERTIFICATES_REQUEST"
export const GET_MICROCERTIFICATES_ERROR = "GET_MICROCERTIFICATES_ERROR"
export const DEL_MICROCERTIFICATES_SUCCESS = "DEL_MICROCERTIFICATES_SUCCESS"
export const DEL_MICROCERTIFICATES_ERROR = "DEL_MICROCERTIFICATES_ERROR"
export const DEL_MICROCERTIFICATES_REQUEST = "DEL_MICROCERTIFICATES_REQUEST"


export const GET_AUDIT_SUCCESS = "GET_AUDIT_SUCCESS"
export const GET_AUDIT_REQUEST = "GET_AUDIT_REQUEST"
export const GET_AUDIT_ERROR = "GET_AUDIT_ERROR"



/*---------------------AUDIT BEGIN-------------------- */
export const getAuditRequest = (payload) => ({
  type: GET_AUDIT_REQUEST,
  payload
})
export const getAuditSuccess = (payload) => ({
  type: GET_AUDIT_SUCCESS,
  payload
})
export const getAuditError = (payload) => ({
  type: GET_AUDIT_ERROR,
  payload
})
/*---------------------AUDIT END-------------------- */

/*---------------------MICROCERTIFICATES BEGIN-------------------- */
export const getMicroCertificatesRequest = (payload) => ({
  type: GET_MICROCERTIFICATES_REQUEST,
  payload
})
export const delMicroCertificatesRequest = (payload) => ({
  type: DEL_MICROCERTIFICATES_REQUEST,
  payload
})

/*---------------------MICROCERTIFICATES END-------------------- */


/*--------------------TRAININGSESSION BEGIN-------------------------*/

export const getTrainingSessionRequest = (payload) => ({
    type: GET_TRAININGSESSION_REQUEST,
    payload
  })

  export const putTrainingSessionRequest = (payload) => ({
    type: PUT_TRAININGSESSION_REQUEST,
    payload
  })
  export const postTrainingSessionRequest = (payload) => ({
    type: POST_TRAININGSESSION_REQUEST,
    payload
  })
  export const delTrainingSessionRequest = (payload) => ({
    type: DEL_TRAININGSESSION_REQUEST,
    payload
  })
/*--------------------TRAININGSESSION END-------------------------*/


/*--------------------OPTIONS BEGIN-------------------------*/

export const getMicrooptionsRequest = (payload) => ({
  type: GET_MICROOPTIONS_REQUEST,
  payload
})

export const setMicrooptionsLocale = (payload) => ({
  type: SET_MICROOPTIONS_LOCAL,
  payload
})
export const postMicrooptionsRequest = (payload) => ({
  type: POST_MICROOPTIONS_REQUEST,
  payload
})

/*--------------------OPTIONS END-------------------------*/



/*--------------------TRAININGGROUP BEGIN-------------------------*/

export const getTrainingGroupRequest = (payload) => ({
    type: GET_TRAININGGROUP_REQUEST,
    payload
  })

  export const putTrainingGroupRequest = (payload) => ({
    type: PUT_TRAININGGROUP_REQUEST,
    payload
  })
  export const postTrainingGroupRequest = (payload) => ({
    type: POST_TRAININGGROUP_REQUEST,
    payload
  })
  export const delTrainingGroupRequest = (payload) => ({
    type: DEL_TRAININGGROUP_REQUEST,
    payload
  })
/*--------------------TRAININGGROUP END-------------------------*/


/*--------------------CERTIFICATE BEGIN-------------------------*/

export const getCertificateRequest = (payload) => ({
  type: GET_CERTIFICATE_REQUEST,
  payload
})

export const putCertificateRequest = (payload) => ({
  type: PUT_CERTIFICATE_REQUEST,
  payload
})
export const postCertificateRequest = (payload) => ({
  type: POST_CERTIFICATE_REQUEST,
  payload
})
export const delCertificateRequest = (payload) => ({
  type: DEL_CERTIFICATE_REQUEST,
  payload
})
/*--------------------CERTIFICATE END-------------------------*/

export const getMicroAreaRequest = (payload) => ({
  type: GET_MICROAREA_REQUEST,
  payload
})
export const getMicroAreaSuccess = (payload) => ({
  type: GET_MICROAREA_SUCCESS,
  payload
})
export const getMicroAreaError = (payload) => ({
  type: GET_MICROAREA_ERROR,
  payload
})



export const clearLocalMicroCategories = (payload) => ({
  type: CLEAR_LOCAL_MICROCATEGORIES,
  payload
})

export const setMicroAreaRequest = (payload) => ({
    type: SET_MICROAREA_REQUEST,
    payload
})

export const setMicroAreaSuccess = (payload) => ({
    type: SET_MICROAREA_SUCCESS,
    payload
})

export const setMicroAreaError = (payload) => ({
    type: SET_MICROAREA_ERROR,
    payload
})

export const getMicroCategoriesRequest = (payload) => ({
    type: GET_MICROCATEGORIES_REQUEST,
    payload
})


export const getMicroCategoriesSuccess = (payload) => ({
    type: GET_MICROCATEGORIES_SUCCESS,
    payload
})

export const getMicroCategoriesError = (error) => ({
    type: GET_MICROCATEGORIES_ERROR,
    error
})


export const setMicroCategoriesRequest = (payload) => ({
    type: SET_MICROCATEGORIES_REQUEST,
    payload
})


export const setMicroCategoriesSuccess = (payload) => ({
    type: SET_MICROCATEGORIES_SUCCESS,
    payload
})

export const setMicroCategoriesError = (error) => ({
    type: SET_MICROCATEGORIES_ERROR,
    error
})


export const delMicroCategoryFromLocalStore = (payload) => ({
    type: DEL_MICROCATEGORY_FROMLOCALSTORE,
    payload
})

export const delMicroAreaRequest = (payload) => ({
    type: SET_MICROAREA_REQUEST,
    payload
})

export const addMicroCategoryToLocalStore = (payload) => ({
    type: ADD_MICROCATEGORY_TOLOCALSTORE,
    payload
})

export const setCurrentMicroArea = (payload) => ({
    type: SET_LOCAL_MICROAREA,
    payload
})

export const setLocalCategorytree = (payload) => ({
  type: SET_LOCAL_CATEGORYTREE,
  payload
})
