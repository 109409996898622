import * as actionTypes from './action';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    error: undefined,
    whatsappdata: [],
    whatsappprofiles: [], 
    categorytree: new Array(), 
    selectOptions: new Array(),
    savestatus: undefined
};

/* BEGIN OF functions for building the categorytree */

 function  buildCatTable(parent, level, allCategoryObjects, catTree) {//recursive function for building categories
  let cats = []

  cats = getConnectedCategories(allCategoryObjects, parent);
      
  if (cats){

  cats.map(cat => {    
    console.log('cats.map first entry')              
    catTree.push({ level: level, category: cat })        
    buildCatTable(cat.id, level + 1, allCategoryObjects, catTree);        
  })
}
};        

function getConnectedCategories(allCategoryObjects, parentid) { // return all categories which belong to the given parent
  let cats =  []
  allCategoryObjects.map(currlevel => {
    
    if ( currlevel.parentId == parentid) {
      cats.push(currlevel);
    }
  });       

  return cats;
}
  
/* END OF BUILDING FUNCTIONS FOR CATEGORYTREE */

// used for component SelectSearch 
function generateOptions(microcats) {

  var categoryObject = new Array();

  microcats ? categoryObject =  Object.values(microcats.map(mc => {
  let localob = Object.assign(mc.category, {  name: mc.category.value, value: mc.category.id,  photo: mc.category.thumbnail })
  
  return localob
}
)) : categoryObject = []

return categoryObject
}


const whatsappReducer =  (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_WHATSAPPDATA_SUCCESS:

          console.log('raw data: ', action.payload)

          state.categorytree = []
          buildCatTable(null, 0, action.payload, state.categorytree)
          console.log('raw data: ', state.categorytree)

          
          var optionsObject = []
          optionsObject =  generateOptions(state.categorytree)

            return Object.assign({}, state, {
                isLoading: false,
                whatsappdata: [...action.payload], 
                selectOptions: optionsObject
            });

        
            case actionTypes.GET_WHATSAPPPROFILE_SUCCESS:
    
                return Object.assign({}, state, {
                    isLoading: false,
                    whatsappprofiles: [...action.payload].filter(Boolean),                    
                    whatsappdata: [], 
                    categorytree: [], 
                    selectOptions: []
                });

        
            case actionTypes.DEL_WHATSAPPDATA_FROMLOCALSTORE: 

        var localcategories = new Array();

        if (action.payload.id)
         state.whatsappdata = state.whatsappdata.filter( f => f.id != action.payload.id);
        if (action.payload.ids)
         state.whatsappdata = state.whatsappdata.filter( f => !action.payload.ids.includes(f));

         state.categorytree = new Array()
         buildCatTable(null, 0, state.whatsappdata, state.categorytree)
         
         var optionsObject = new Array()
         optionsObject =  generateOptions(state.categorytree)         

        return Object.assign({}, state, {
          isLoading: false,
          whatsappdata:  [...state.whatsappdata], 
          selectOptions: optionsObject                
      });

      case actionTypes.ADD_WHATSAPPDATA_TOLOCALSTORE: 

      state.categorytree = new Array()
      state.whatsappdata.push(action.payload.category)
      buildCatTable(null, 0, state.whatsappdata, state.categorytree)

      var optionsObject = new Array()
      optionsObject =  generateOptions(state.categorytree)      

      return Object.assign({}, state, {
        isLoading: false,
        whatsappdata:  state.whatsappdata, 
        selectOptions: optionsObject                 
    });      

    case actionTypes.ADD_WHATSAPPPROFILE_TOLOCALSTORE:     

    return Object.assign({}, state, {
      isLoading: false,
      whatsappprofiles:  [...state.whatsappprofiles, action.payload.profile],       
  }); 

        case actionTypes.GET_WHATSAPPDATA_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });


            case actionTypes.SET_WHATSAPPDATA_ERROR:
            
            Swal.fire({
              title: 'Error!',
              position: 'center',
              text: 'Problem beim speichern der Daten!',
              type: 'error',
              animation: true,
              customClass: {
                popup: 'animated tada'
              },       
              showConfirmButton: false, 
              timer: 1500
              //confirmButtonText: 'Cool'
            })  
            return Object.assign({}, state, {
                isLoading: false,
                savestatus: false
            });

            case actionTypes.SET_WHATSAPPDATA_SUCCESS:
            
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Daten erfolgreich gespeichert \n',
              showConfirmButton: false,
              timer: 1500
            })          
            
            console.log('raw data: ', action.payload)

            state.categorytree = []
            buildCatTable(null, 0, action.payload, state.categorytree)
            console.log('raw data: ', state.categorytree)
  
            
            var optionsObject = []
            optionsObject =  generateOptions(state.categorytree)            

            return Object.assign({}, state, {
                isLoading: false,
                whatsappdata: [...action.payload] ,
                savestatus: true, 
                selectOptions: optionsObject
            });

            case actionTypes.SET_WHATSAPPPROFILE_SUCCESS:
            
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Daten erfolgreich gespeichert \n',
              showConfirmButton: false,
              timer: 1500
            })          
            console.log('data is now ', [...state.whatsappprofiles, action.payload] )   
            
            let wprofs = state.whatsappprofiles

            if (action.payload.method === "POST")
              wprofs = [...state.whatsappprofiles, action.payload.profile]
            else if (action.payload.method === "PUT"){
              wprofs = state.whatsappprofiles.map(e => { 
                if (e.id === action.payload.profile.id)
                return action.payload.profile

                return e
              }
                )

            }

            return Object.assign({}, state, {
                whatsappprofiles: [...wprofs], 
                isLoading: false,
                savestatus: true
            });


            case actionTypes.DEL_WHATSAPPPROFILE_SUCCESS:
            
            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Daten erfolgreich gelöscht \n',
              showConfirmButton: false,
              timer: 1500
            })          
            console.log('data is now ', ...action.payload.profiles )   
            return Object.assign({}, state, {
                whatsappprofiles: [...action.payload.profiles], 
                isLoading: false,
                savestatus: true
            });


        default:
            return state;
    }





}

export default whatsappReducer;