import * as actionTypes from './actionSuperGroups';

const initialState = {
    isLoading: false,
    error: null,
    updateDate: null,
    superGroups: [] 
};

const SuperGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SUPERGROUPS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                superGroups: action.payload.superGroups
            });

            case actionTypes.UPDATE_SUPERGROUP_CONFIGURATION_LOCALSTORE: {
                // state.superGroup.categories = action.payload.categories
                const supergroup = state.superGroups.find( c => c.id == action.payload.sgid)
                supergroup.configuration = action.payload.configuration

                 return Object.assign({}, state, {
                    isLoading: false,
                    updateDate: Date.now(),
                    superGroups: [...state.superGroups]
                });            
                 
             }              

            case actionTypes.UPDATE_GROUPS_LOCALSTORE: {                
                //state = local store
                //action.payload = uebergabe
    
                const supergroup = state.superGroups.find( c => c.id == action.payload.sgid)
                
                supergroup.groups.push(action.payload.group);
                

                return Object.assign({}, state, {
                    isLoading: false,
                    updateDate: Date.now(),
                    superGroups: [...state.superGroups]
                });            
    
            };     
            
            case actionTypes.DELETE_GROUP_LOCALSTORE: {                
                //state = local store
                //action.payload = uebergabe
                
                action.payload.groupstodelete.map( entry => {
                        console.log('ENTRY IN LOCALSTORE IS: ', entry);
                 var supergroup = state.superGroups.find( c => c.id == entry.sgid);
                 supergroup.groups= supergroup.groups.filter( f => f.id!=entry.gid);

                }
                )
                
                
                //supergroup.groups.push(action.payload.group);
                
                

                return Object.assign({}, state, {
                    isLoading: false,
                    updateDate: Date.now(),
                    superGroups: [...state.superGroups]
                });
            };            


        case actionTypes.SUPERGROUPS_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

export default SuperGroupReducer;