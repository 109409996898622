/**
 * Clip action types
 */
export const GET_CLIPS_REQUEST = 'GET_CLIPS_REQUEST';
export const GET_CLIPS_SUCCESS = 'GET_CLIPS_SUCCESS';

export const GET_CLIPSINGLE_REQUEST = 'GET_CLIP_SINGLE_REQUEST';
export const GET_CLIPSINGLE_SUCCESS = 'GET_CLIP_SINGLE_SUCCESS';

export const CLIP_SYNC_REQUEST = 'CLIP_SYNC_REQUEST';
export const UPDATECLIP_LOCALSTORE = 'CLIPS_UPDATELOCAL';

// Subtitle
export const TRANSCRIPTION_REQUEST = 'TRANSCRIPTION_REQUEST';
export const TRANSLATION_REQUEST = 'TRANSLATION_REQUEST';
export const UPDATE_SUBTITLES_REQUEST = 'UPDATE_SUBTITLES_REQUEST';
export const BURN_SUBTITLES_REQUEST = 'BURN_SUBTITLES_REQUEST';

export const CLIPS_ERROR = 'CLIPS_ERROR';
export const CLIPS_CREATE = 'CLIPS_CREATE';
export const CLIPS_CREATE_JOOBSTERDB = 'CLIPS_CREATE_JOOBSTERDB';

export const SET_CLIPS_SHOTS_BASKET = "SET_CLIPS_SHOTS_BASKET"


export const setClipsShotsBasket = (payload) => ({
  type: SET_CLIPS_SHOTS_BASKET,
  payload
});

export const getClipsRequest = (payload) => ({
    type: GET_CLIPS_REQUEST,
    payload
});

export const getClipsSuccess = (payload) => ({
    type: GET_CLIPS_SUCCESS,
    payload
});

export const clipSyncRequest = (payload) => ({
    type: CLIP_SYNC_REQUEST,
    payload
});

export const clipSingleRequest = (payload) => ({
    type: GET_CLIPSINGLE_REQUEST,
    payload
});

export const updateClipLocalStore = (payload) => ({
    type: UPDATECLIP_LOCALSTORE,
    payload
});

export const transcriptionRequest = (payload) => ({
    type: TRANSCRIPTION_REQUEST,
    payload
});

export const translationRequest = (payload) => ({
    type: TRANSLATION_REQUEST,
    payload
});

export const updateSubtitlesRequest = (payload) => ({
    type: UPDATE_SUBTITLES_REQUEST,
    payload
});

export const burnSubtitlesRequest = (payload) => ({
    type: BURN_SUBTITLES_REQUEST,
    payload
});

export const clipsError = (error) => ({
    type: CLIPS_ERROR,
    error
});

export const clipsCreate = (payload) => ({
    type: CLIPS_CREATE,
    payload
});

export const syncClipJoobsterDB = (payload) => ({
    type: CLIPS_CREATE_JOOBSTERDB,
    payload
});
