import * as actionTypes from './action';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    error: undefined,
    templateSections: [],
};


const sectionReducerForSupergroup = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_TEMPLATESECTIONSFORSUPERGROUP_SUCCESS:

            return Object.assign({}, state, {
                isLoading: false,
                templateSections: [...action.payload]
                
            });
            case actionTypes.SET_TEMPLATESECTIONSFORSUPERGROUP_SUCCESS:

            Swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Kategorie erfolgreich gespeichert \n',
                showConfirmButton: false,
                timer: 1500
              }) 

              console.log('success is: ', ...action.payload)
            return Object.assign({}, state, {
                isLoading: false,
                templateSections: [...action.payload]
                
            });

        case actionTypes.DEL_TEMPLATESECTIONSFORSUPERGROUP_FROMLOCALSTORE: 

        var localcategories = new Array();

        if (action.payload.name)
         localcategories = state.templateSections.filter( f => f.name != action.payload.name);
        


        return Object.assign({}, state, {
          isLoading: false,
          templateSections:  [...localcategories]                   
      });

      case actionTypes.ADD_TEMPLATESECTIONSFORSUPERGROUP_TOLOCALSTORE: 

      state.templateSections.push(action.payload.section)

      return Object.assign({}, state, {
        isLoading: false,
        templateSections:  state.templateSections                   
    });      

        case actionTypes.GET_TEMPLATESECTIONSFORSUPERGROUP_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

            case actionTypes.SET_TEMPLATESECTIONSFORSUPERGROUP_ERROR:

            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Problem beim speichern der Kategorie!',
                type: 'error',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },       
                showConfirmButton: false, 
                timer: 1500
                //confirmButtonText: 'Cool'
              })  

            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

        default:
            return state;
    }
}

export default sectionReducerForSupergroup;