import * as actionTypes from './action';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    error: undefined,
    trainingcontent: []
};


const trainingContentReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_TRAININGCONTENT_SUCCESS:

            return Object.assign({}, state, {
                isLoading: false,
                trainingcontent: [...action.payload.trainingcontent]
            });



        case actionTypes.GET_TRAININGCONTENT_ERROR:

            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });

            case actionTypes.UPDATE_TRAININGCONTENT_SUCCESS:

                return Object.assign({}, state, {
                    isLoading: false,
                    trainingcontent: [...action.payload.trainingcontent]
                });

            case actionTypes.UPDATE_TRAININGCONTENT_ERROR:

                return Object.assign({}, state, {
                    isLoading: false,
                    error: action.payload.error
                });

                case actionTypes.DELETE_TRAININGCONTENT_SUCCESS:

                  return Object.assign({}, state, {
                      isLoading: false,
                      trainingcontent: [...action.payload.trainingcontent]
                  });

              case actionTypes.DELETE_TRAININGCONTENT_ERROR:

                  return Object.assign({}, state, {
                      isLoading: false,
                      error: action.payload.error
                  });


                case actionTypes.POST_TRAININGCONTENT_SUCCESS:

                    return Object.assign({}, state, {
                        isLoading: false,
                        trainingcontent: [...action.payload.trainingcontent]
                    });

                case actionTypes.POST_TRAININGCONTENT_ERROR:

                    return Object.assign({}, state, {
                        isLoading: false,
                        error: action.payload.error
                    });

                    case actionTypes.CHANGE_LOCALSTORE_TRAININGCONTENT:











                        var localtc = state.trainingcontent.find( f => f.id === action.payload.id)
                        localtc.values = action.payload.values

                        console.log('CHANGE_LOCALSTORE: ', localtc.values)

                      //  state.trainingcontent[0].values = localtc.values

                   //   let tccopy = Object.assign([], [...state.trainingcontent])
                   //   tccopy[state.trainingcontent.findIndex( i => i.id === action.payload.id)].values = action.payload.values

                        return Object.assign({}, state, {
                          // trainingcontent: action.payload.values

                        });
/*
            case actionTypes.SET_MICROCATEGORIES_ERROR:

            Swal.fire({
              title: 'Error!',
              position: 'center',
              text: 'Problem beim speichern der Daten!',
              type: 'error',
              animation: true,
              customClass: {
                popup: 'animated tada'
              },
              showConfirmButton: false,
              timer: 1500
              //confirmButtonText: 'Cool'
            })
            return Object.assign({}, state, {
                isLoading: false,
                savestatus: false
            });

            case actionTypes.SET_MICROCATEGORIES_SUCCESS:

            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Daten erfolgreich gespeichert \n',
              showConfirmButton: false,
              timer: 1500
            })
            return Object.assign({}, state, {
                isLoading: false,
                savestatus: true
            });

            case actionTypes.SET_MICROAREA_SUCCESS:

            Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Daten erfolgreich gespeichert \n',
              showConfirmButton: false,
              timer: 1500
            })
            console.log('data is now ', [...state.microareas, action.payload] )

            let wprofs = state.microareas

            if (action.payload.method === "POST")
              wprofs = [...state.microareas, action.payload.areas]
            else if (action.payload.method === "PUT"){
              wprofs = state.microareas.map(e => {
                if (e.id === action.payload.profile.id)
                return action.payload.profile

                return e
              }
                )

            }

            return Object.assign({}, state, {
                microareas: [...wprofs],
                isLoading: false,
                savestatus: true
            });
            */

        default:
            return state;
    }





}

export default trainingContentReducer;
